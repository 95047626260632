import { Box3, Group, OrthographicCamera, PerspectiveCamera, Sphere, Vector3 } from "three";
import { Controls } from "viewer/core/controls";
import { scene } from "viewer/core/scene";

const width = window.innerWidth;
const height = window.window.innerHeight;

const fov = 50;
const aspect = width / height;
const near = 0.1;
const far = 5000;
const frustum = 28;
const left = width / -frustum;
const right = width / frustum;
const top = height / frustum;
const bottom = height / -frustum;

export type ActiveCamera = "perspective" | "orthographic";

class Camera {
  private readonly perspectiveCamera = new PerspectiveCamera(fov, aspect, near, far);
  private readonly orthographicCamera = new OrthographicCamera(left, right, top, bottom, near, far);
  public activeCamera: ActiveCamera = "perspective";

  /** Returns PerspectiveCamera instance */
  public get perspective(): PerspectiveCamera {
    return this.perspectiveCamera;
  }

  /** Returns OrthographicCamera instance */
  public get orthographic(): OrthographicCamera {
    return this.orthographicCamera;
  }

  /** Returns active camera instance */
  public get active(): PerspectiveCamera | OrthographicCamera {
    switch (this.activeCamera) {
      case "perspective":
        return this.perspectiveCamera;
      case "orthographic":
        return this.orthographicCamera;
    }
  }

  /** Toggles between perspective and orthographic camera */
  public toggle(): ActiveCamera {
    return (this.activeCamera = this.activeCamera === "perspective" ? "orthographic" : "perspective");
  }

  /** Resets both cameras to initial view / with top view option */
  public reset(model: Group, controls: Controls, topView = false): void {
    const { center, distance } = Camera.computeCameraPosition(model);

    controls.reset();

    Camera.resetPosition(this.perspectiveCamera, center, distance, topView);
    Camera.resetPosition(this.orthographicCamera, center, distance, topView);
  }

  /** Updates both cameras by current viewport */
  public update(): void {
    const width = window.innerWidth;
    const height = window.window.innerHeight;

    this.perspectiveCamera.aspect = width / height;

    this.orthographicCamera.left = width / -frustum;
    this.orthographicCamera.right = width / frustum;
    this.orthographicCamera.top = height / frustum;
    this.orthographicCamera.bottom = height / -frustum;

    this.perspectiveCamera.updateProjectionMatrix();
    this.orthographicCamera.updateProjectionMatrix();
  }

  /** Resets position of given camera */
  private static resetPosition(
    camera: PerspectiveCamera | OrthographicCamera,
    center: Vector3,
    distance: number,
    topView = false
  ): void {
    camera.zoom = 1;
    camera.position.copy(center);
    !topView && camera.translateX(distance);
    camera.translateY(!topView ? distance / 2 : distance * 2);
    !topView && camera.translateZ(distance);
    camera.lookAt(scene.position);
    camera.updateProjectionMatrix();
  }

  /** Computes camera position by model */
  private static computeCameraPosition(model: Group): { center: Vector3; distance: number } {
    const box = new Box3().setFromObject(model);
    const boundingSphere = new Sphere();
    box.getBoundingSphere(boundingSphere);
    const distance = boundingSphere.radius + boundingSphere.radius * 0.2;

    return { center: new Vector3(), distance };
  }
}

export const camera = new Camera();
