import { Group, Mesh, Vector3 } from "three";
import { RealProjected } from "viewer/measurement/interface";
import { Dot, Dots, Lines, Polygon, Volume } from "viewer/geometry";
import { getVectorsOnModel } from "viewer/utils";

export type LinesType = "default" | "projected";

export class Geometry {
  public readonly dots: Dots = new Dots();
  public readonly lines: Lines = new Lines();
  public readonly polygon: Polygon = new Polygon();
  public readonly volume: Volume = new Volume();

  private model?: Group;

  public hasLines = false;
  public hasPolygon = false;
  public hasRaycastedLines = false;

  private linesType: LinesType = "default";

  public get vectors(): Vector3[] {
    return this.dots.vectors;
  }

  public get firstVector(): Vector3 | undefined {
    return this.dots.firstVector;
  }

  public get lastVector(): Vector3 | undefined {
    return this.dots.lastVector;
  }

  public get validPolygonVectors(): boolean {
    return this.dots.length > 2;
  }

  /** Validates if the polygon can be closed */
  public get validPolygonLines(): boolean {
    const a = this.lastVector;
    const b = this.firstVector;

    if (!this.model || !a || !b) return false;

    return !!getVectorsOnModel(a, b, this.model)?.length;
  }

  /** Polygon visibility getter - used for validation - invisible polygon is not valid (failed triangulation) */
  public get polygonVisible(): boolean {
    return this.polygon.visible;
  }

  public get meshes(): Mesh[] {
    return this.dots.meshes;
  }

  public get lengths(): RealProjected[] {
    return this.lines.lengths;
  }

  public get notes(): string[] {
    return this.dots.getNotes();
  }

  public getDot(index: number): Dot | undefined {
    return this.dots.getDot(index);
  }

  public addDot(position: Vector3): Dot {
    return this.dots.add(position);
  }

  public removeDot(index: number, recreateLines = false, closePolygon = false): void {
    this.dots.remove(index);
    recreateLines && this.recreateLines(closePolygon);
  }

  /** Updates dots position - used in edit control mode */
  public setDotPosition(index: number, position: Vector3): Dot | undefined {
    const dot = this.dots.getDot(index);

    if (!dot) return undefined;

    dot.setPosition(position);
    dot.scaleByCamera();

    return dot;
  }

  /** Toggles dot highlight - used for edit control mode */
  public toggleDotHighlight(index: number): Dot | undefined {
    const dot = this.dots.getDot(index);

    if (!dot) return undefined;

    dot.toggleHighlight();

    return dot;
  }

  /** Adds new line */
  public addLine([a, b]: [Vector3, Vector3]): void {
    this.lines.add(a, b);
  }

  /** Updates line position - used in edit control mode */
  public updateLine(index: number, [a, b]: [Vector3, Vector3]): boolean {
    const valid = this.lines.update(index, a, b);
    return this.linesType === "default" || valid;
  }

  /** Recreates all lines (used after dot remove) */
  public recreateLines(close = false): void {
    this.lines.clear();
    this.lines.addFromArray(this.vectors);
    close && this.lines.close(this.vectors);
  }

  /** Sets lines type - volume measurement uses projected lines */
  public setLinesType(type: LinesType): void {
    this.linesType = type;
    this.lines.setRaycast(type === "projected");
  }

  /** If possible, opens the polygon (removes last line) */
  public openPolygon(): void {
    if (this.hasPolygon) this.lines.remove(-1);
  }

  /** If possible, closes the polygon (adds last line) */
  public closePolygon(): void {
    if (this.hasPolygon) this.lines.close(this.vectors);
  }

  /** Sets note to coordinate/dot with given index */
  public setNote = (index: number, value: string): void => this.dots.setNote(index, value);

  public setModel(model: Group): void {
    this.model = model;
    this.lines.setModel(model);
  }

  /** Scales Dots - should be used on controls change */
  public scale(): void {
    this.dots.scaleByCamera();
  }

  /** Clears all measurement geometry */
  public clear = (): void => {
    this.dots.clear();
    this.lines.clear();
    this.polygon.clear();
    this.volume.removePoints();
  };

  /** Cleanup - disposes everything  */
  public cleanup = (): void => {
    this.dots.clear();
    this.lines.clear();
    this.polygon.cleanup();
    this.volume.cleanup();
  };
}
