import { AmbientLight, Scene } from "three";

/** Create Scene instance */
const scene = new Scene();

/** Set scene */
scene.add(new AmbientLight("#ffffff"));
scene.fog = null;

export { scene };
