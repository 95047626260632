import { Triangle, Vector3 } from "three";
import cdt2d from "cdt2d";

/** Calculates new triangles from array of vectors */
export function triangulate(vectors: Vector3[]): { flatTriangles: number[]; triangles: Triangle[] } {
  const triangles = calculateTriangles(vectors);
  return validateTriangles(triangles.length, vectors.length)
    ? { flatTriangles: flattenTriangles(triangles), triangles: createTriangles(vectors, triangles) }
    : { flatTriangles: [], triangles: [] };
}

/** Tries to calculate triangles with cdt2d */
function calculateTriangles(vectors: Vector3[]): number[][] {
  const { points, edges } = flatten(vectors);

  try {
    return cdt2d(points, edges, { exterior: false });
  } catch (e) {
    return [];
  }
}

/** Flattens triangles from calculateTriangles function */
function flattenTriangles(triangles: number[][]): number[] {
  return triangles.reduce<number[]>((p, c) => [...p, ...c], []);
}

/** Creates Triangles from cdt2d triangles */
function createTriangles(vectors: Vector3[], triangles: number[][]): Triangle[] {
  return triangles.reduce<Triangle[]>((p, [a, b, c]) => [...p, new Triangle(vectors[a], vectors[b], vectors[c])], []);
}

/** Validates triangles count */
function validateTriangles(triangles: number, vectors: number): boolean {
  return triangles >= vectors - 2;
}

/** Prepares vectors for cdt2d */
function flatten(vectors: Vector3[]): { points: number[][]; edges: number[][] } {
  const points = [] as number[][];
  const edges = [] as number[][];

  for (let i = 0; i < vectors.length; i++) {
    points.push([vectors[i].x, vectors[i].z]);
    edges.push([i, i !== vectors.length - 1 ? i + 1 : 0]);
  }

  return { points, edges };
}
