import { ComponentChildren, Fragment, FunctionalComponent, h } from "preact";

interface Props {
  stores: FunctionalComponent<{ children: ComponentChildren }>[];
  children: ComponentChildren;
}

const reducer = ({ stores, children }: Props) => stores.reduceRight((child, Store) => <Store>{child}</Store>, children);

export const Compose = (props: Props) => <Fragment>{reducer(props)}</Fragment>;
