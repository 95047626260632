import { ComponentChildren, createContext, h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { GeometryChangeHandler, SettingsChangeHandler, VolumeChangeHandler } from "viewer/measurement/interface";
import { DEFAULT_METHOD, SettingsInput } from "viewer/measurement/Volume";
import { viewer } from "viewer";

const DEFAULT_VALUE = {
  method: DEFAULT_METHOD,
  precision: 1,
  y: 0,

  settingsChanged: false,
  loadingPercent: 0,
  disabled: false,
  loading: false,

  values: viewer.measurement.values.getVolumeValues(),

  setVolume: (() => {}) as typeof viewer.measurement.setVolume,
  applyChanges: () => {},
  abort: () => {},
};

export const VolumeStoreContext = createContext(DEFAULT_VALUE);

interface Props {
  children: ComponentChildren;
}

export const VolumeStore = ({ children }: Props) => {
  const [method, setMethod] = useState(DEFAULT_VALUE.method);
  const [precision, setPrecision] = useState(DEFAULT_VALUE.precision);
  const [y, setY] = useState(DEFAULT_VALUE.y);

  const [settingsChanged, setSettingsChanged] = useState(DEFAULT_VALUE.settingsChanged);
  const [loadingPercent, setLoadingPercent] = useState(DEFAULT_VALUE.loadingPercent);
  const [disabled, setDisabled] = useState(DEFAULT_VALUE.disabled);
  const [loading, setLoading] = useState(DEFAULT_VALUE.loading);

  const [values, setValues] = useState(DEFAULT_VALUE.values);

  const setVolume = useCallback((input: SettingsInput) => viewer.measurement.setVolume(input), []);

  const applyChanges = useCallback(async () => {
    setLoading(true);
    setDisabled(true);
    setSettingsChanged(false);
    await viewer.measurement.calculateVolume();
  }, []);

  const abort = useCallback(() => {
    viewer.measurement.abortVolume();
    setSettingsChanged(false);
    setDisabled(false);
    setLoading(false);
  }, []);

  const handleSettingsChange = useCallback<SettingsChangeHandler>((method, precision, y, requireApply) => {
    setMethod(method);
    setPrecision(precision);
    setY(y);

    setSettingsChanged(requireApply);
  }, []);

  const handleGeometryChange = useCallback<GeometryChangeHandler>((requireApply) => {
    setSettingsChanged(requireApply);
  }, []);

  const handleVolumeChange = useCallback<VolumeChangeHandler>((loading, progress, values) => {
    setLoading(loading);
    setDisabled(loading);
    setLoadingPercent(progress);
    values && setValues(values);
  }, []);

  useEffect(() => {
    viewer.measurement.setSettingsChangeHandler(handleSettingsChange);
    viewer.measurement.setGeometryChangeHandler(handleGeometryChange);
    viewer.measurement.setVolumeChangeHandler(handleVolumeChange);
  }, [handleGeometryChange, handleSettingsChange, handleVolumeChange]);

  const store = {
    method,
    precision,
    y,
    settingsChanged,
    loadingPercent,
    disabled,
    loading,
    values,
    setVolume,
    applyChanges,
    abort,
  };

  return <VolumeStoreContext.Provider value={store}>{children}</VolumeStoreContext.Provider>;
};
