import { h } from "preact";
import { Link } from "components/Link";
import { Info } from "components/Panel/Footer/Info";
import { useInfo } from "components/Panel/Footer/useInfo";
import { Controls } from "components/Panel/Footer/Controls";
import { useUIStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export const Footer = () => {
  const { t } = useLocale();

  const { hasMode } = useUIStore();

  const info = useInfo();

  if (!hasMode) return null;

  return (
    <div class="panel-footer">
      <Controls />

      <Info type={info.type} />

      <div class="panel-footer__buttons">
        <div class="flex-center-v">
          <Link icon={"infoCircle"} onClick={info.toggleModelInfo}>
            {t("panel.footer.model")}
          </Link>

          <Link icon={"questionMarkCircle"} onClick={info.toggleMeasurementInfo}>
            {t("panel.footer.measurement")}
          </Link>
        </div>

        <Link icon={"export"} onClick={info.toggleExport}>
          {t("panel.footer.csv")}
        </Link>
      </div>
    </div>
  );
};
