import { h } from "preact";
import { Loading } from "components/Loading";
import { useViewerInit } from "hooks";
import "./styles.css";

export const Model = () => {
  const { canvasContainerRef, isLoading, loadingProgress } = useViewerInit();

  return (
    <div ref={canvasContainerRef} class="model__container">
      <Loading visible={isLoading} percent={loadingProgress} />
    </div>
  );
};
