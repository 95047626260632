import { calculateSampleSize, calculateSamplesPerSecond } from "viewer/utils";
import { MeasurementValues } from "viewer/measurement/Values";
import { Geometry } from "viewer/measurement/Geometry";
import * as I from "viewer/measurement/interface";
import { worker } from "viewer/workers";

export type Method = "smart" | "horizontal";

export type Settings = { method: Method; precision: number; y: number };
export type SettingsInput = { method?: Method; precision?: number; y?: number };

export const DEFAULT_METHOD: Method = "smart";

export class Volume {
  private method: Method = DEFAULT_METHOD;
  private precision = 1;
  private y = 0;

  private samplesPerSecond = -1;

  public firstCalculation = true;
  public inProgress = false;

  /** Calculates new volume based on polygon */
  public async calculate(
    geometry: Geometry,
    values: MeasurementValues,
    onChange: I.VolumeChangeHandler
  ): Promise<void> {
    if (this.inProgress || !geometry.validPolygonVectors) return;

    if (this.samplesPerSecond === -1) this.samplesPerSecond = await calculateSamplesPerSecond();

    geometry.volume.removePoints();

    this.inProgress = true;

    onChange(true, 0);

    // const start = window.performance.now();

    worker.calculateVolume(
      geometry.polygon.vertices,
      calculateSampleSize(values.getValues().area.projected, this.samplesPerSecond, this.precision),
      (progress) => {
        geometry.volume.renderPoints(progress.projections, progress.precision);
        onChange(true, progress.percent);
      },
      (volume) => {
        // console.log("Performance[ms]:", window.performance.now() - start);
        // console.log("Sample count:", volume.sampleCount);

        onChange(false, 100, values.setVolume(volume));

        geometry.volume.mergePoints();

        this.inProgress = false;
      }
    );

    if (this.firstCalculation) this.firstCalculation = false;
  }

  /** Aborts volume calculation in progress */
  public abort(geometry: Geometry): void {
    if (!this.inProgress) return;

    worker.abort();
    this.inProgress = false;
    geometry.volume.removePoints();
  }

  /** Shows UI volume values */
  public showValues(values: MeasurementValues, onChange: I.VolumeChangeHandler): void {
    onChange(false, 0, values.getVolumeValues());
  }

  /** Hides UI volume values */
  public hideValues(values: MeasurementValues, onChange: I.VolumeChangeHandler): void {
    onChange(false, 0, values.getVolumeValues(true));
  }

  /** Clears UI volume values */
  public clearValues(values: MeasurementValues, onChange: I.VolumeChangeHandler): void {
    onChange(false, 0, values.setVolume());
  }

  public methodEquals(method: Method): boolean {
    return method === this.method;
  }

  /** Volume measurement settings setter */
  public set(values: MeasurementValues, settings: SettingsInput, before: () => any): Settings | undefined {
    if (this.inProgress) return;

    const { method, precision, y } = settings;

    if (method === undefined && precision === undefined && this.methodEquals("smart")) return;

    before();

    if (method !== undefined && this.method !== method) this.method = method;
    if (precision !== undefined) this.precision = precision;
    if (y !== undefined) this.y = values.subtractGeodeticSurvey(y, "z");

    return {
      method: this.method,
      precision: this.precision,
      y: values.addGeodeticSurvey(this.y, "z"),
    };
  }

  /** Returns set altitude for polygon correction */
  public getY(): number {
    return this.y;
  }
}
