import { ComponentChildren, h } from "preact";
import { Compose } from "store/Compose";
import { UIStore } from "store/UI";
import { UnitsStore } from "store/Units";
import { ValuesStore } from "store/Values";
import { VolumeStore } from "store/Volume";

interface Props {
  children: ComponentChildren;
}

const stores = [UIStore, UnitsStore, ValuesStore, VolumeStore];

export const StoreProvider = ({ children }: Props) => <Compose stores={stores}>{children}</Compose>;
