import { Group, Texture, TextureLoader, Vector3 } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { CoordinateSystem, fetchModelData, ModelType } from "viewer/core/api";
import { logException } from "utils/sentry";

type ModelFileFormat = "obj" | "fbx" | "glb";

export enum LoaderErrors {
  "api_error" = "api_error",
  "missing_model" = "missing_model",
  "model_not_supported" = "model_not_supported",
  "loading_model_failed" = "loading_model_failed",
}

export const isLoaderError = (error: string): boolean => {
  return (
    [
      LoaderErrors.api_error,
      LoaderErrors.missing_model,
      LoaderErrors.model_not_supported,
      LoaderErrors.loading_model_failed,
    ] as string[]
  ).includes(error);
};

type Loader = OBJLoader | FBXLoader | GLTFLoader;

type UrlParameters = {
  id?: string;
  model?: string;
  texture?: string;
  xyz?: string;
  type?: string;
  inScale?: boolean;
  coordinateSystem?: string;
};

type ParsedUrlParameters = UrlParameters & {
  type: ModelType;
  inScale: boolean;
  coordinateSystem?: CoordinateSystem;
  format?: ModelFileFormat;
};

type OnProgressHandler = (percent: number) => any;

type Assets = {
  model?: Group;
  texture?: Texture;
  xyz: Vector3;
  type: ModelType;
  coordinateSystem?: CoordinateSystem;
  inScale: boolean;
};

/** Loads assets and configuration from URL parameters */
export async function load(onProgress: OnProgressHandler): Promise<Assets> {
  const parameters = parseUrlParams();

  if (parameters.id) {
    const data = await fetchModelData(parameters.id);

    if (!data) throw new Error(LoaderErrors.api_error);

    parameters.model = data.model_url;
    parameters.texture = data.texture_url;
    parameters.xyz = data.coordinates_url;
    parameters.type = data.type;
    parameters.inScale = data.isScale;
    parameters.format = resolveModelFileFormat(data.model_url);
    parameters.coordinateSystem = data.coordinate_system || undefined;
  }

  if (!parameters.model) throw new Error(LoaderErrors.missing_model);
  if (!parameters.format) throw new Error(LoaderErrors.model_not_supported);

  const [model, texture, xyz] = await Promise.all([
    loadModel(parameters.format, parameters.model, (percent) => onProgress(percent * 0.9)),
    loadTexture(parameters.texture),
    loadXYZFile(parameters.xyz),
  ]);

  onProgress(95);

  if (!model) throw new Error(LoaderErrors.loading_model_failed);

  return {
    model,
    texture,
    xyz,
    type: parameters.type,
    coordinateSystem: parameters.coordinateSystem,
    inScale: parameters.inScale,
  };
}

/** Parses parameters from URL */
function parseUrlParams(): ParsedUrlParameters {
  const params: ParsedUrlParameters = { type: "simple", inScale: false };

  window.location.search
    .slice(1)
    .split("&")
    .map((queryParam) => {
      const [key, value] = queryParam.split("=");
      return { key, value };
    })
    .forEach(({ key, value }) => {
      if (key === "id") params.id = decodeURIComponent(value);
      if (key === "model") params.model = decodeURIComponent(value);
      if (key === "texture") params.texture = decodeURIComponent(value);
      if (key === "xyz") params.xyz = decodeURIComponent(value);
      if (key === "type") params.type = parseModelType(value);
      if (key === "system") params.coordinateSystem = (value as CoordinateSystem) || undefined;
      if (key === "in_scale") params.inScale = true;
    });

  if (params.model) params.format = resolveModelFileFormat(params.model);

  return params;
}

/** Returns model file format based on model url */
function resolveModelFileFormat(modelUrl: string): ModelFileFormat | undefined {
  const { pathname, searchParams } = new URL(modelUrl);
  const rscd = searchParams.get("rscd") ?? ""; // extract file format from rscd param for Azure blobstorage source
  const path = pathname ?? "";

  if (path.endsWith(".obj") || rscd.endsWith(".obj")) return "obj";
  if (path.endsWith(".fbx") || rscd.endsWith(".fbx")) return "fbx";
  if (path.endsWith(".glb") || rscd.endsWith(".glb")) return "glb";
}

/** Returns model Loader instance based on file format */
function getLoaderInstance(format: ModelFileFormat): Loader {
  switch (format) {
    case "obj":
      return new OBJLoader();
    case "fbx":
      return new FBXLoader();
    case "glb":
      return new GLTFLoader();
  }
}

/** Loads model and return its Group instance */
async function loadModel(
  format: ModelFileFormat,
  url: string,
  onProgress: OnProgressHandler
): Promise<Group | undefined> {
  const loader = getLoaderInstance(format);

  return new Promise((resolve) => {
    const handleProgress = (e: ProgressEvent) => onProgress(Math.round((e.loaded * 100) / e.total));
    const handleError = (error: ErrorEvent) => {
      logException(error);
      resolve(undefined);
    };

    return loader instanceof GLTFLoader
      ? loader.load(url, (gltf) => resolve(gltf.scene), handleProgress, handleError)
      : loader.load(url, (group) => resolve(group), handleProgress, handleError);
  });
}

/** Loads texture if provided */
async function loadTexture(url?: string): Promise<Texture | undefined> {
  if (!url) return undefined;

  const loader = new TextureLoader();

  return new Promise((resolve) =>
    loader.load(
      url,
      (texture) => resolve(texture),
      () => {},
      () => resolve(undefined)
    )
  );
}

/** Load XYZ file if provided and return Vector3 with geodetic survey */
async function loadXYZFile(url?: string): Promise<Vector3> {
  return new Promise((resolve) => {
    if (!url) return resolve(new Vector3());

    const raw = new XMLHttpRequest();

    raw.onload = () => resolve(parseXYZFile(raw.response));

    raw.open("GET", url);
    raw.send();
  });
}

/** Parses XYZ files content */
function parseXYZFile(content: string): Vector3 {
  const values = content.split(" ").map((value: string) => parseFloat(value));

  return new Vector3(values[0] || 0, values[1] || 0, values[2] || 0);
}

/** Parses model type from URL parameter value */
function parseModelType(value?: string): ModelType {
  if (value === "scale") return "scale";
  if (value === "georef_maps") return "georef_maps";
  if (value === "georef_spray") return "georef_spray";
  if (value === "georef_qr") return "georef_qr";
  return "simple";
}
