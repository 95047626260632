import "unfetch/polyfill";
import { mockData } from "assets/apiMock";
import { getEnv } from "utils/env";

type Maybe<T> = T | null;

export type CoordinateSystem =
  | "s_jtsk"
  | "dhdn"
  | "wgs84"
  | "hd72"
  | "wgs84_utm_32n"
  | "wgs84_utm_34n"
  | "wgs84_utm_35n"
  | "wgs84_utm_36n"
  | "wgs84_utm_37n"
  | "dhdn_3d_gk_z2"
  | "dhdn_3d_gk_z4"
  | "dhdn_3d_gk_z5"
  | "dhdn_31466"
  | "dhdn_31467"
  | "dhdn_31468"
  | "etrs89_poland_cs2000_5"
  | "etrs89_poland_cs2000_6"
  | "etrs89_poland_cs2000_7"
  | "etrs89_poland_cs2000_8"
  | "etrs89_utm_32n"
  | "etrs89_utm_33n"
  | "ch1903_plus_lv95"
  | "unknown";

export type ModelType = "simple" | "scale" | "georef_maps" | "georef_spray" | "georef_qr";

export interface ApiData {
  model_url: Maybe<string>;
  texture_url: Maybe<string>;
  coordinates_url: Maybe<string>;
  coordinate_system: Maybe<CoordinateSystem>;
  type: Maybe<ModelType>;
}

interface Data {
  model_url: string;
  texture_url?: string;
  coordinates_url?: string;
  coordinate_system?: CoordinateSystem;
  isScale: boolean;
  type: ModelType;
}

const isProduction = getEnv("NODE_ENV") === "production";

export async function fetchModelData(id: string): Promise<Data | undefined> {
  try {
    if (isMock(id)) return validate(mock(id));

    const url = createUrl(id);
    const response = await fetch(url);
    const data = await response.json();
    return validate(data as ApiData);
  } catch (e) {}
}

function createUrl(id: string): string {
  let url = getEnv("API_URL");

  if (!url.endsWith("/")) url += "/";

  return url + id;
}

function validate(data: ApiData): Data {
  if (
    typeof data !== "object" ||
    !data.hasOwnProperty("model_url") ||
    !data.hasOwnProperty("texture_url") ||
    !data.hasOwnProperty("coordinates_url") ||
    !data.hasOwnProperty("coordinate_system") ||
    !data.hasOwnProperty("type")
  ) {
    throw new Error();
  }

  if (data.model_url === null || data.type === null) throw new Error();

  return {
    model_url: data.model_url,
    texture_url: data.texture_url ?? undefined,
    coordinates_url: data.coordinates_url ?? undefined,
    coordinate_system: data.coordinate_system ?? undefined,
    isScale: data.type !== "simple",
    type: data.type,
  };
}

const DEMO_IDS = ["demo", "demo2", "demo3"];

function isMock(id: string): boolean {
  return (!isProduction && Object.keys(mockData).includes(id)) || DEMO_IDS.includes(id);
}

function mock(id: string): ApiData {
  return mockData[id];
}
