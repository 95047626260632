import { MapControls } from "three/examples/jsm/controls/OrbitControls";
import { camera } from "viewer/core/camera";
import { renderer } from "viewer/core/renderer";
import { augmentMapControls } from "viewer/utils";

// Modify Three.js MapControls prototype to support manual zooming
augmentMapControls();

const canvas = renderer.domElement || document.createElement("canvas");

/** Acts as a camera rig - each controls interaction will affect both cameras */
export class Controls {
  private readonly perspective: MapControls = new MapControls(camera.perspective, canvas);
  private readonly orthographic: MapControls = new MapControls(camera.orthographic, canvas);

  private lastZoom = 1;
  private lastRotation = 1;
  private onZoom: () => any = () => {};
  private onRotate: () => any = () => {};

  public reset(): void {
    this.perspective.reset();
    this.orthographic.reset();
  }

  public update(): void {
    this.perspective.update();
    this.orthographic.update();
  }

  public zoomIn(): void {
    this.perspective.zoomIn();
    this.orthographic.zoomIn();
  }

  public zoomOut(): void {
    this.perspective.zoomOut();
    this.orthographic.zoomOut();
  }

  public lookDown(): void {
    this.perspective.lookDown();
    this.orthographic.lookDown();
  }

  public togglePanning(): boolean {
    this.perspective.screenSpacePanning = !this.perspective.screenSpacePanning;
    this.orthographic.screenSpacePanning = !this.orthographic.screenSpacePanning;

    return this.perspective.screenSpacePanning && this.orthographic.screenSpacePanning;
  }

  public registerKeyEvents(element: HTMLElement): void {
    this.perspective.listenToKeyEvents(element);
    this.orthographic.listenToKeyEvents(element);
  }

  public registerListeners(zoomHandler: () => any, rotationHandler: () => any): void {
    this.onZoom = zoomHandler;
    this.onRotate = rotationHandler;

    this.perspective.addEventListener("change", this.perspectiveZoomHandler);
    this.orthographic.addEventListener("change", this.orthographicZoomHandler);
  }

  public removeListeners(): void {
    this.perspective.removeEventListener("change", this.perspectiveZoomHandler);
    this.orthographic.removeEventListener("change", this.orthographicZoomHandler);
  }

  /** Triggers onZoom callback only if zoom or position has changed - scaling on rotation makes the Cursor jump */
  private perspectiveZoomHandler = (): void => {
    const rotation = parseFloat(this.perspective.object.rotation.x.toFixed(5));
    const zoom = parseFloat(this.perspective.target.distanceTo(camera.perspective.position).toFixed(5));
    const rotationChanged = this.lastRotation !== rotation;
    const scale = this.lastZoom !== zoom || !rotationChanged;

    if (camera.activeCamera === "perspective" && rotationChanged) this.onRotate();
    if (camera.activeCamera === "perspective" && scale) this.onZoom();
    if (camera.activeCamera === "perspective") {
      this.lastRotation = rotation;
      this.lastZoom = zoom;
    }
  };

  /** Triggers onZoom callback only if zoom or position has changed - scaling on rotation makes the Cursor jump */
  private orthographicZoomHandler = (): void => {
    const rotation = parseFloat(this.orthographic.object.rotation.x.toFixed(5));
    const zoom = camera.orthographic.zoom;
    const rotationChanged = this.lastRotation !== rotation;
    const scale = this.lastZoom !== zoom || !rotationChanged;

    if (camera.activeCamera === "orthographic" && rotationChanged) this.onRotate();
    if (camera.activeCamera === "orthographic" && scale) this.onZoom();
    if (camera.activeCamera === "orthographic") {
      this.lastRotation = rotation;
      this.lastZoom = zoom;
    }
  };
}

export const controls = new Controls();
