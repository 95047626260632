import { h } from "preact";
import "./styles.css";

interface Props {
  visible: boolean;
  percent: number;
}

export const Loading = ({ visible, percent }: Props) => {
  const width = `${percent}%`;
  const height = visible && percent < 100 ? 5 : 0;

  return (
    <div class="loading__container">
      <div class="loading__bar" style={{ width, height }} />
    </div>
  );
};
