import { BufferGeometry, Group, MathUtils, Mesh, MeshBasicMaterial, Texture } from "three";
import { camera, controls, CoordinateSystem, devtools, ModelType, renderer, scene } from "viewer/core";
import {
  CoordsCsvHeaderValues,
  downloadCoordsCsv,
  downloadModelScreenshot,
  downloadValuesCsv,
  ValuesCsvHeaderValues,
  ValuesCsvLabelValues,
} from "viewer/utils";
import { isLoaderError, load, LoaderErrors } from "viewer/core/loader";
import { Measurement } from "viewer/measurement";
import { worker } from "viewer/workers";
import { ConversionUnit } from "utils/format";

export class Viewer {
  private model?: Group;

  private frameId = 0;

  public inScale = false;
  public coordinateSystem?: CoordinateSystem;
  public modelType: ModelType = "simple";

  public measurement: Measurement = new Measurement();

  constructor() {
    this.registerListeners();
  }

  /** Loads assets, prepares raw model, sets core instances, renders canvas, and starts animation loop */
  public async init(
    domElement: HTMLDivElement,
    onProgress: (percent: number) => any,
    onError: (error?: LoaderErrors) => any
  ): Promise<void> {
    if (!renderer.isCreated || !renderer.domElement) return onError(LoaderErrors.loading_model_failed);

    try {
      const { model, texture, xyz, coordinateSystem, inScale, type } = await load(onProgress);

      if (!model) return;

      onProgress(100);

      const geometry = this.preprocessModel(model, texture);

      this.model = model;
      this.inScale = inScale;
      this.modelType = type;
      this.coordinateSystem = coordinateSystem;
      this.measurement.initModel(model);
      this.measurement.values.setGeodeticSurvey(xyz);

      renderer.setRenderer(!texture);
      camera.reset(model, controls);
      controls.registerKeyEvents(renderer.domElement);

      scene.add(model);

      domElement.appendChild(renderer.domElement);

      this.animate();

      renderer.focus();

      worker.loadGeometry(geometry);
    } catch (e: any) {
      return onError(isLoaderError(e.message) ? e.message : LoaderErrors.missing_model);
    }
  }

  /** Camera controls methods */
  public zoomIn = () => controls.zoomIn();
  public zoomOut = () => controls.zoomOut();
  public togglePanning = () => controls.togglePanning();
  public topView = () => {
    controls.lookDown();
    this.scale();
  };

  public resetCamera = () => {
    if (this.model) camera.reset(this.model, controls);
    this.scale();
  };

  public toggleCamera = () => {
    const activeCamera = camera.toggle();
    this.scale();

    return activeCamera;
  };

  public activeCamera = camera.activeCamera;

  /** Downloads model screenshot */
  public capture = (): void => {
    downloadModelScreenshot(renderer.getScreenshot(scene, camera.active));
  };

  /** Downloads CSV file generated from measurement coordinates and notes */
  public downloadCoordsCsv = (header?: CoordsCsvHeaderValues, coordinateSystem?: string): void => {
    const coordinates = this.measurement.values.getValues().coordinates;
    const notes = this.measurement.geometry.notes;

    downloadCoordsCsv(coordinates, notes, coordinateSystem, header);
  };

  /** Downloads CSV file generated from measurement values */
  public downloadValuesCsv = (
    labels?: ValuesCsvLabelValues,
    header?: ValuesCsvHeaderValues,
    conversion: ConversionUnit = "m"
  ): void => {
    const mode = this.measurement.getMode();
    const { length, area, perimeter, height } = this.measurement.values.getValues();
    const volume = this.measurement.values.getVolumeValues();

    downloadValuesCsv(mode, this.inScale, conversion, length, volume, area, perimeter, height, labels, header);
  };

  /** Cleanup - geometry, listeners and animation frame disposal */
  public cleanup = (): void => {
    renderer.dispose();
    this.unregisterListeners();
    this.measurement.cleanup();
    window.cancelAnimationFrame(this.frameId);
  };

  /** Preprocesses raw model before render */
  private preprocessModel(model: Group, texture?: Texture): BufferGeometry | undefined {
    let geometry: BufferGeometry | undefined = undefined;

    model.traverse((object) => {
      if (!(object instanceof Mesh) || Array.isArray(object.material)) return;

      const mesh = object;

      // Apply BVH for RayCasting optimization
      mesh.geometry.computeBoundsTree();

      // Pre-init texture and apply it to the mesh
      Viewer.initTexture(mesh, texture);

      geometry = mesh.geometry;
    });

    // three.js uses Y as vertical axis - rotate the model
    model.rotateX(MathUtils.degToRad(-90));

    model.matrixAutoUpdate = false;
    model.updateMatrix();

    return geometry;
  }

  /** Applies texture to the mesh if provided and tries to init texture before first render (optimization) */
  private static initTexture(mesh: Mesh, texture?: Texture): void {
    if (texture) {
      mesh.material = new MeshBasicMaterial({ map: texture });
      renderer.initTexture(texture);
      return;
    }

    try {
      const _material = mesh.material as any;
      const isMaterialMapTexture = _material.hasOwnProperty("map") && _material.map instanceof Texture;

      if (isMaterialMapTexture) return;

      renderer.initTexture(_material.map);
    } catch (e) {
      // Texture is applied even if it fails
      // It's just a possible optimization for fbx and glb formats
    }
  }

  /** Starts animation loop - DO NOT CHANGE INNER CALL ORDER */
  private animate = (): void => {
    if (!this.model) return;

    devtools.statsBegin();

    controls.update();
    this.measurement.update();

    renderer.render(scene, camera.active);

    devtools.statsEnd();

    this.frameId = window.requestAnimationFrame(this.animate);
  };

  /** Scales measurement geometry */
  private scale(scaleCursor = true): void {
    if (!this.model) return;

    scaleCursor && this.measurement.update(true);
    this.measurement.geometry.scale();
  }

  /** Window resize handler - updates camera and renderer */
  private handleWindowResize = (): void => {
    camera.update();
    renderer.update();
  };

  /** Controls change handler */
  private handleControlsChange = (): void => {
    this.scale();
  };

  /** Controls change handler for rotation */
  private handleControlsRotationChange = (): void => {
    this.scale(false);
  };

  /** Registers event listeners */
  private registerListeners(): void {
    window.addEventListener("resize", this.handleWindowResize, false);
    controls.registerListeners(this.handleControlsChange, this.handleControlsRotationChange);
  }

  /** Removes event listeners */
  private unregisterListeners(): void {
    window.removeEventListener("resize", this.handleWindowResize, false);
    controls.removeListeners();
  }
}
