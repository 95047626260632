import { Fragment, h } from "preact";
import "./styles.css";

type Item<T> = { label: string; value: T };

interface Props<T> {
  items: Item<T>[];
  selected: T;
  disabled?: boolean;
  onChange: (value: T) => any;
}

export const ButtonBar = <T extends number | string>({ items, selected, disabled = false, onChange }: Props<T>) => (
  <div class={`button-bar ${disabled ? "disabled" : ""}`}>
    {items.map(({ value, label }) => {
      const active = selected === value;

      return (
        <Fragment key={value}>
          <div
            class={`button-bar__button ${active ? "selected" : ""}`}
            onClick={!disabled && !active ? () => onChange(value) : () => {}}
          >
            {label}
          </div>
        </Fragment>
      );
    })}
  </div>
);
