import { Vector3 } from "three";
import { worker } from "viewer/workers";

const MAGIC = 1.4236;

/** Returns calculated samples per second */
export async function calculateSamplesPerSecond(): Promise<number> {
  await test();
  const t1 = await test(0.5, 0.5);
  const t2 = await test(1, 0.5);
  const t3 = await test(0.5, 1);
  const t4 = await test(2, 3);

  const avg = parseFloat(((t1 + t2 + t3 + t4) / 4).toFixed(5)) * MAGIC;

  return Math.round(1000 / avg);
}

/** Returns performance in ms per sample */
function test(a = 1, b = 1): Promise<number> {
  const start = window.performance.now();
  return new Promise<number>((resolve) => {
    worker.test(
      [new Vector3(0, -1, 0), new Vector3(0, -1, b), new Vector3(a, -1, b), new Vector3(a, -1, 0)],
      [new Vector3(0, 0, 0), new Vector3(0, 0, b), new Vector3(a, 0, b), new Vector3(a, 0, 0)],
      0.01,
      () => {},
      (volume) => resolve((window.performance.now() - start) / volume.sampleCount)
    );
  });
}
