import { Vector3 } from "three";

export enum InputMessageType {
  geometry,
  calculate,
  test,
}

export type Geometry = { position: Float32Array; index: Uint32Array };
export type Polygon = { vertices: Vector3[]; sampleSize: number };
export type Test = { model: Vector3[] } & Polygon;

export type InputMessage =
  | { type: InputMessageType.geometry; input: Geometry }
  | { type: InputMessageType.calculate; input: Polygon }
  | { type: InputMessageType.test; input: Test };

export enum OutputMessageType {
  progress,
  volume,
}

export type Progress = {
  percent: number;
  precision: number;
  projections: { above: Float32Array; below: Float32Array };
};

export type Volume = {
  area: number;
  cut: number;
  fill: number;
  netFill: number;
  precision: number;
  sampleCount: number;
  incompleteness: number;
};

export type OutputMessage =
  | { type: OutputMessageType.progress; output: Progress }
  | { type: OutputMessageType.volume; output: Volume };
