import { h } from "preact";
import { Icon, IconType } from "components/Icon";
import "./styles.css";

interface Props {
  children: string;
  icon?: IconType;
  size?: "md" | "lg";
  padding?: boolean;
  disabled?: boolean;
  onClick?: () => any;
}

export const Link = ({ children, icon, size = "md", padding = true, disabled = false, onClick }: Props) => (
  <div
    class={`link link--${size} text-100 ${!padding ? "link--no-padding" : ""} ${disabled ? "link--disabled" : ""}`}
    onClick={onClick}
  >
    {icon && <Icon type={icon} />}
    <span>{children}</span>
  </div>
);
