import { h } from "preact";
import "./styles.css";
import { Icon } from "components/Icon";

interface Props {
  checked: boolean;
  label?: string;
  onClick: () => any;
}

export const Checkbox = ({ checked, label, onClick }: Props) => {
  return (
    <div class="checkbox" onClick={onClick}>
      <div class={`checkbox__input ${checked ? "checkbox__input--checked" : ""}`}>
        <Icon type={"check"} />
      </div>
      {label && <span class="checkbox__label text-gray-800">{label}</span>}
    </div>
  );
};
