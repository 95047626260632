import { Group, Vector3 } from "three";
import { Dot } from "viewer/geometry/Dot";
import { CursorLine } from "viewer/geometry/CursorLine";
import { RealProjected } from "viewer/measurement/interface";

const CURSOR_COLOR = "#ffff00";
const INVALID_CURSOR_COLOR = "#ff0000";

export class Cursor {
  private readonly dot: Dot = new Dot(false, CURSOR_COLOR);
  private readonly line: CursorLine = new CursorLine(false, CURSOR_COLOR, INVALID_CURSOR_COLOR);

  public lastPosition: Vector3 | undefined;
  public valid = true;

  /** Updates cursor position and visibility */
  public update(position?: Vector3, lineOrigin?: Vector3, firstVector?: Vector3): void {
    this.lastPosition = position;
    this.valid = true;

    if (!position) {
      this.line.hide();
      this.dot.hide();
      return;
    }

    this.dot.setPosition(position);
    this.dot.scaleByCamera();
    this.dot.show();

    if (!lineOrigin) {
      this.toggleColor();
      this.line.hide();
      return;
    }

    this.valid = this.line.update(lineOrigin, position, firstVector);
    this.toggleColor();
    this.line.show();
  }

  public updateLineOrigin(origin?: Vector3): void {
    if (!origin) return this.line.hide();

    if (this.lastPosition) this.valid = this.line.update(origin, this.lastPosition);

    this.toggleColor();
  }

  public setModel(model: Group): void {
    this.line.setModel(model);
  }

  public setLineRaycast(raycast: boolean): void {
    this.line.setRaycast(raycast);
  }

  public get lineLength(): RealProjected {
    return this.line.length;
  }

  /** Cleanup - geometry disposal  */
  public cleanup(): void {
    this.line.dispose();
    this.dot.dispose();
  }

  /** Changes cursors dot color if needed */
  private toggleColor(): void {
    if (!this.valid && this.dot.lastColor !== INVALID_CURSOR_COLOR) this.dot.changeColor(INVALID_CURSOR_COLOR);
    if (this.valid && this.dot.lastColor === INVALID_CURSOR_COLOR) this.dot.changeColor(CURSOR_COLOR);
  }
}
