import { h } from "preact";
import { Header } from "components/Panel/Header";
import { Coords } from "components/Panel/Coords";
import { Distance } from "components/Panel/Distance";
import { Area } from "components/Panel/Area";
import { Volume } from "components/Panel/Volume";
import { Footer } from "components/Panel/Footer";
import { Toolbar } from "components/Toolbar";
import { useUIStore } from "store";
import "./styles.css";

export const Panel = () => {
  const { mode, hasMode: isOpen } = useUIStore();

  return (
    <div class={`panel ${isOpen ? "panel--open" : ""}`}>
      <div class="panel__content">
        <Header />

        {mode === "coords" && <Coords />}
        {mode === "distance" && <Distance />}
        {mode === "area" && <Area />}
        {mode === "volume" && <Volume />}

        <Footer />
      </div>

      <Toolbar />
    </div>
  );
};
