import { Mesh, Vector3 } from "three";
import { List } from "immutable";
import { Dot } from "viewer/geometry/Dot";

export class Dots {
  private dots = List<Dot>();
  private notes = List<string>();

  /** Returns dots count */
  public get length(): number {
    return this.dots.size;
  }

  /** Returns all dots positions in array */
  public get vectors(): Vector3[] {
    return this.dots.toArray().map((dot) => dot.position.clone());
  }

  /** Returns first dot position if not empty */
  public get firstVector(): Vector3 | undefined {
    return this.dots.first<Dot>()?.position.clone();
  }

  /** Returns last dot position if not empty */
  public get lastVector(): Vector3 | undefined {
    return this.dots.last<Dot>()?.position.clone();
  }

  /** Returns outerMesh instances of each dot */
  public get meshes(): Mesh[] {
    return this.dots.toArray().map((dot) => dot.mesh);
  }

  /** Returns dot instance by index */
  public getDot(index: number): Dot | undefined {
    return this.dots.get(index);
  }

  /** Returns all notes as an array */
  public getNotes(): string[] {
    return this.notes.toArray();
  }

  /** Adds new Dot for given coordinate */
  public add(position: Vector3): Dot {
    const dot = new Dot();
    dot.setPosition(position.clone());
    dot.scaleByCamera();
    dot.setLabel((this.dots.size + 1).toString());

    this.dots = this.dots.push(dot);
    this.notes = this.notes.push("");

    return dot;
  }

  /** Removes dot with given index */
  public remove(index: number): void {
    this.dots.get(index)?.dispose();
    this.dots = this.dots.delete(index);
    this.dots.forEach((dot, i) => {
      return dot.setLabel((i + 1).toString());
    });

    this.notes = this.notes.delete(index);
  }

  /** Sets note value for dot with given index */
  public setNote(index: number, value: string): void {
    this.notes = this.notes.set(index, value);
  }

  /** Removes all Dots from scene and clears List */
  public clear(): void {
    this.dots.forEach((dot) => dot.dispose());
    this.dots = this.dots.clear();
    this.notes = this.notes.clear();
  }

  /** Scales all Dots - should be called on controls change  */
  public scaleByCamera(): void {
    this.dots.forEach((dot) => dot.scaleByCamera());
  }
}
