type Option<T> = [string, T];

/** Get locale from URL parameters - fallbacks to the first provided option */
export const resolveLocaleFromUrl = <T>(options: Option<T>[], paramName = "locale"): T => {
  if (!options.length) throw new Error("At least one option must be provided.");

  const urlLocale = findUrlParam(paramName);

  for (const [name, value] of options) {
    if (urlLocale === name) return value;
  }

  return options[0][1];
};

const findUrlParam = (name: string): string | undefined => {
  return window.location.search
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .find((p) => p[0] === name)?.[1];
};
