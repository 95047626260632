import { useCallback, useState } from "preact/hooks";
import { viewer } from "viewer";

export const useViewerControls = () => {
  const { activeCamera, toggleCamera: toggleCam, togglePanning: togglePan, ...controls } = viewer;

  const [perspectiveOn, setPerspectiveOn] = useState(activeCamera === "perspective");
  const toggleCamera = useCallback(() => setPerspectiveOn(toggleCam() === "perspective"), [toggleCam]);

  const [verticalPanningOn, setVerticalPanningOn] = useState(false);
  const togglePanning = useCallback(() => setVerticalPanningOn(togglePan), [togglePan]);

  return { perspectiveOn, toggleCamera, verticalPanningOn, togglePanning, ...controls };
};
