import { useMemo } from "preact/hooks";
import { viewer } from "viewer";
import { useLocale } from "locale";

export const useCoordinateSystem = () => {
  const { t } = useLocale();
  const { coordinateSystem: coordinateSystemKey } = viewer;

  const coordinateSystem = useMemo(() => {
    if (coordinateSystemKey === "s_jtsk") return t("coordinateSystem.sJtsk");
    if (coordinateSystemKey === "dhdn") return t("coordinateSystem.dhdn");
    if (coordinateSystemKey === "wgs84") return t("coordinateSystem.wgs84");
    if (coordinateSystemKey === "hd72") return t("coordinateSystem.hd72");
    if (coordinateSystemKey === "wgs84_utm_32n") return t("coordinateSystem.wgs84Utm32n");
    if (coordinateSystemKey === "wgs84_utm_34n") return t("coordinateSystem.wgs84Utm34n");
    if (coordinateSystemKey === "wgs84_utm_35n") return t("coordinateSystem.wgs84Utm35n");
    if (coordinateSystemKey === "wgs84_utm_36n") return t("coordinateSystem.wgs84Utm36n");
    if (coordinateSystemKey === "wgs84_utm_37n") return t("coordinateSystem.wgs84Utm37n");
    if (coordinateSystemKey === "dhdn_3d_gk_z2") return t("coordinateSystem.dhdn3dGkZ2");
    if (coordinateSystemKey === "dhdn_3d_gk_z4") return t("coordinateSystem.dhdn3dGkZ4");
    if (coordinateSystemKey === "dhdn_3d_gk_z5") return t("coordinateSystem.dhdn3dGkZ5");
    if (coordinateSystemKey === "dhdn_31466") return t("coordinateSystem.dhdn31466");
    if (coordinateSystemKey === "dhdn_31467") return t("coordinateSystem.dhdn31467");
    if (coordinateSystemKey === "dhdn_31468") return t("coordinateSystem.dhdn31468");
    if (coordinateSystemKey === "etrs89_poland_cs2000_5") return t("coordinateSystem.etrs89PolandCs20005");
    if (coordinateSystemKey === "etrs89_poland_cs2000_6") return t("coordinateSystem.etrs89PolandCs20006");
    if (coordinateSystemKey === "etrs89_poland_cs2000_7") return t("coordinateSystem.etrs89PolandCs20007");
    if (coordinateSystemKey === "etrs89_poland_cs2000_8") return t("coordinateSystem.etrs89PolandCs20008");
    if (coordinateSystemKey === "etrs89_utm_32n") return t("coordinateSystem.etrs89Utm32n");
    if (coordinateSystemKey === "etrs89_utm_33n") return t("coordinateSystem.etrs89Utm33n");
    if (coordinateSystemKey === "ch1903_plus_lv95") return t("coordinateSystem.ch1903PlusLv95");
    if (coordinateSystemKey === "unknown") return t("coordinateSystem.unknown");
  }, [coordinateSystemKey, t]);

  return { coordinateSystem };
};
