import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Icon } from "components/Icon";
import { useUIStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export const Header = () => {
  const { t } = useLocale();
  const { mode, requestModeChange } = useUIStore();

  const closePanel = useCallback(() => requestModeChange(undefined), [requestModeChange]);

  const heading = {
    none: "",
    coords: t("panel.coords.title"),
    distance: t("panel.distance.title"),
    area: t("panel.area.title"),
    volume: t("panel.volume.title"),
  }[mode ?? "none"];

  return (
    <div class="panel__header">
      <span class="text-200 text-medium">{heading}</span>
      <div class="panel__header-close" onClick={closePanel}>
        <Icon type={"close"} />
      </div>
    </div>
  );
};
