import { useMemo } from "preact/hooks";
import { useValuesStore } from "store";
import { viewer } from "viewer";
import { useCoordinateSystem } from "hooks/useCoordinateSystem";

export const useViewerMeasurement = () => {
  const { modelType, inScale } = viewer;
  const { removeCoordinate, selectCoordinate, simulateRightClick } = viewer.measurement;
  const { notes: getNotes, setNote } = viewer.measurement.geometry;

  const { coordinates, lines, cursor, selected } = useValuesStore();
  const { coordinateSystem } = useCoordinateSystem();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const notes = useMemo(() => getNotes, [coordinates]);

  return {
    modelType,
    coordinateSystem,
    inScale,
    coordinates,
    lines,
    cursor,
    selected,
    notes,
    removeCoordinate,
    selectCoordinate,
    simulateRightClick,
    setNote,
  };
};
