import * as I from "viewer/measurement/interface";

const DEFAULT_HANDLER = () => {};

export class Handlers {
  protected onValuesChange: I.ValuesChangeHandler = DEFAULT_HANDLER;
  protected onGeometryChange: I.GeometryChangeHandler = DEFAULT_HANDLER;
  protected onControlModeChange: I.ControlModeChangeHandler = DEFAULT_HANDLER;
  protected onSettingsChange: I.SettingsChangeHandler = DEFAULT_HANDLER;
  protected onVolumeChange: I.VolumeChangeHandler = DEFAULT_HANDLER;

  /** Handler setter for onValuesChange - for UI */
  public setValuesChangeHandler(onValuesChange: I.ValuesChangeHandler): void {
    this.onValuesChange = onValuesChange;
  }

  /** Handler setter for onControlModeChange - for UI */
  public setControlModeChangeHandler(onControlModeChange: I.ControlModeChangeHandler): void {
    this.onControlModeChange = onControlModeChange;
  }

  /** Handler setter for onGeometryChange - for UI */
  public setGeometryChangeHandler(onGeometryChange: I.GeometryChangeHandler): void {
    this.onGeometryChange = onGeometryChange;
  }

  /** Handler setter for onPolygonTypeChange - for UI */
  public setSettingsChangeHandler(onSettingsChange: I.SettingsChangeHandler): void {
    this.onSettingsChange = onSettingsChange;
  }

  /** Handler setter for onVolumeChange - for UI */
  public setVolumeChangeHandler(onVolumeChange: I.VolumeChangeHandler): void {
    this.onVolumeChange = onVolumeChange;
  }

  protected clearHandlers(): void {
    this.onValuesChange = DEFAULT_HANDLER;
    this.onGeometryChange = DEFAULT_HANDLER;
    this.onControlModeChange = DEFAULT_HANDLER;
    this.onSettingsChange = DEFAULT_HANDLER;
    this.onVolumeChange = DEFAULT_HANDLER;
  }
}
