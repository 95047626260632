import { h } from "preact";
import { Icon } from "components/Icon";
import { useViewerMeasurement } from "hooks";
import { formatValue } from "utils/format";
import { useVolumeStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

interface Props {
  distanceOnly?: boolean;
}

export const Lines = ({ distanceOnly = false }: Props) => {
  const { t } = useLocale();
  const { disabled } = useVolumeStore();
  const { lines, cursor, selected, inScale, removeCoordinate, selectCoordinate } = useViewerMeasurement();

  return (
    <div class="panel__table-container">
      <table class="panel__table">
        <tr class="text-100">
          <th class="text-center col-sm">{t("common.point")}</th>
          <th>{t("panel.distance.realDistance")}</th>
          <th>{t("panel.distance.projectedDistance")}</th>
          {!distanceOnly && <th>{t("panel.distance.slope")}</th>}
          {!distanceOnly && <th class="col-angle">{t("panel.distance.angle")}</th>}
          <th class="col-sm" />
        </tr>

        {lines.map(({ length, slope, angle }, i) => {
          const isCursor = i === cursor;
          const canRemove = !disabled && !isCursor && selected === undefined;

          const remove = () => canRemove && removeCoordinate(i);
          const onClickIndex = !disabled && !isCursor ? () => selectCoordinate(i) : undefined;

          return (
            <tr key={`point-${i}`} class={`text-gray-800 ${i === selected ? "selected" : ""}`}>
              <td class={`text-center ${isCursor || disabled ? "text-gray-600" : "pointer"}`} onClick={onClickIndex}>
                {i + 1}
              </td>
              <td>{formatValue(length.real, inScale)}</td>
              <td>{formatValue(length.projected, inScale)}</td>
              {!distanceOnly && <td>{`${slope}%`}</td>}
              {!distanceOnly && <td>{`${angle}°`}</td>}
              <td
                class={canRemove ? "panel__table-remove" : "panel__table-remove--disabled"}
                title={t("buttons.remove")}
                onClick={remove}
              >
                <div class="flex-center">
                  <Icon type={canRemove ? "trash" : "trashDisabled"} />
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
