import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Icon, IconType } from "components/Icon";
import "./styles.css";

interface Props<T> {
  value: T;
  icon: IconType;
  label: string;
  description: string;
  active: boolean;
  disabled?: boolean;
  onClick: (value: T) => any;
}

export const RadioBox = <T extends string>({
  value,
  icon,
  label,
  description,
  active,
  disabled = false,
  onClick,
}: Props<T>) => {
  const handleClick = useCallback(() => !disabled && !active && onClick(value), [disabled, active, onClick, value]);

  return (
    <div class={`radio-box__container ${disabled ? "disabled" : ""} ${active ? "active" : ""}`} onClick={handleClick}>
      <div class="radio-box__top-row">
        <div class="radio-box__button-outer">
          <div class="radio-box__button-inner" />
        </div>
        <Icon type={icon} />
        <span class="radio-box__label text-gray-800">{label}</span>
      </div>

      <div class="radio-box__description-container">
        <span class="text-100 text-gray-700">{description}</span>
      </div>
    </div>
  );
};
