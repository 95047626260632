import { ComponentChildren, createContext, h } from "preact";
import { StateUpdater, useEffect, useState } from "preact/hooks";
import { LoaderErrors, Mode, viewer } from "viewer";

function stateUpdater<T>(): StateUpdater<T> {
  return () => {};
}

const DEFAULT_VALUE = {
  mode: viewer.measurement.getMode(),
  setMode: stateUpdater<Mode>(),
  requestedMode: undefined as Mode,
  setRequestedMode: stateUpdater<Mode>(),

  controlMode: viewer.measurement.getControlMode(),

  help: false,
  setHelp: stateUpdater<boolean>(),

  warning: false,
  setWarning: stateUpdater<boolean>(),
  warningPrevented: false,
  setWarningPrevented: stateUpdater<boolean>(),

  error: undefined as LoaderErrors | undefined,
  setError: stateUpdater<LoaderErrors | undefined>(),
};

export const UIStoreContext = createContext(DEFAULT_VALUE);

interface Props {
  children: ComponentChildren;
}

export const UIStore = ({ children }: Props) => {
  const [mode, setMode] = useState(DEFAULT_VALUE.mode);
  const [requestedMode, setRequestedMode] = useState(DEFAULT_VALUE.requestedMode);
  const [controlMode, setControlMode] = useState(DEFAULT_VALUE.controlMode);

  const [help, setHelp] = useState(DEFAULT_VALUE.help);

  const [warning, setWarning] = useState(DEFAULT_VALUE.warning);
  const [warningPrevented, setWarningPrevented] = useState(DEFAULT_VALUE.warningPrevented);

  const [error, setError] = useState(DEFAULT_VALUE.error);

  useEffect(() => viewer.measurement.setControlModeChangeHandler(setControlMode), []);
  useEffect(() => viewer.measurement.setMode(mode), [mode]);

  const store = {
    mode,
    setMode,
    requestedMode,
    setRequestedMode,
    controlMode,
    help,
    setHelp,
    warning,
    setWarning,
    warningPrevented,
    setWarningPrevented,
    error,
    setError,
  };

  return <UIStoreContext.Provider value={store}>{children}</UIStoreContext.Provider>;
};
