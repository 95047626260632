import { useEffect, useRef, useState } from "preact/hooks";
import { useUIStore } from "store";
import { viewer } from "viewer";

export const useViewerInit = () => {
  const { setError } = useUIStore();

  const canvasContainerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    if (!canvasContainerRef.current) return;

    viewer.init(canvasContainerRef.current, setLoadingProgress, setError).finally(() => setIsLoading(false));

    return viewer.cleanup;
  }, [setError]);

  return { canvasContainerRef, isLoading, loadingProgress };
};
