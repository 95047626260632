type EnvKey = "NODE_ENV" | "API_URL" | "SENTRY_DSN" | "SENTRY_RELEASE";

/*
 * getEnv must contain full definitions, process.env[key] will not work
 *
 * "SENTRY_RELEASE" is set in package.json
 * */
export const getEnv = (key: EnvKey): string => {
  let value;

  if (key === "NODE_ENV") value = process.env.NODE_ENV;
  if (key === "API_URL") value = process.env.API_URL;
  if (key === "SENTRY_DSN") value = process.env.SENTRY_DSN;
  if (key === "SENTRY_RELEASE") value = process.env.SENTRY_RELEASE;

  if (value !== undefined) return value;

  throw new Error(`ENV variable "${key}" is not defined.`);
};
