import { BufferGeometry, Group, LineSegments, Mesh, Raycaster, Vector3 } from "three";
import { dispose } from "viewer/utils/mesh";

const EVEREST = 8848; // Raycaster origin Y
const MIN_SAMPLE_SIZE = 0.002;
const FIRST_HIT = true;

const raycaster = new Raycaster();
const vectorDown = new Vector3(0, -1, 0);

/** Returns vectors on the model between two points */
export function getVectorsOnModel(a: Vector3, b: Vector3, model: Group, sampleSize = 0.1): Vector3[] | undefined {
  raycaster.firstHitOnly = FIRST_HIT;

  const { vectors } = getVectorsBetween(a, b, sampleSize, EVEREST);

  for (const vector of vectors) {
    raycaster.set(vector, vectorDown);

    const intersections = raycaster.intersectObject(model, true);

    if (!intersections.length) return;

    vector.copy(FIRST_HIT ? intersections[0].point : intersections[intersections.length - 1].point);
  }

  return [a, ...vectors, b];
}

/** Returns vectors between two vertices */
export function getVectorsBetween(
  a: Vector3,
  b: Vector3,
  sampleSize = 0.1,
  y?: number
): { sampleSize: number; vectors: Vector3[] } {
  if (y !== undefined) {
    a = a.clone();
    b = b.clone();
    a.y = b.y = y;
  }

  const distance = a.distanceTo(b);
  const sampleCount = Math.round(distance / sampleSize) || 1;

  const vectors: Vector3[] = [];
  const t = 1 / sampleCount;

  for (let i = 1; i < sampleCount; i++) vectors.push(new Vector3().lerpVectors(a, b, t * i));

  return { sampleSize: distance / sampleCount, vectors };
}

/** Sets specific y coordinate to given vectors */
export function modifyVectorsY(vectors: Vector3[], y: number): Vector3[] {
  if (!vectors.length) return [];

  return vectors.map((vector) => vector.clone().setY(y));
}

type VolumeValues = {
  area: number;
  cut: number;
  fill: number;
  netFill: number;
  precision: number;
  sampleCount: number;
  incompleteness: number;
};

type OnProgressHandler = (
  percent: number,
  precision: number,
  projections: { above: Float32Array; below: Float32Array }
) => any;

/** Calculates volume */
export function calculateVolume(
  vertices: Vector3[],
  model: Group | Mesh,
  polygon: Mesh,
  sampleSize: number,
  onProgress: OnProgressHandler = () => {}
): VolumeValues {
  const { samples: sampleRows, precision } = calculatePolygonSamples(vertices, sampleSize);
  const edges = createPolygonEdgeLines(vertices);

  const values = { sampleCount: 0, area: 0, volume: { above: 0, below: 0 } };

  let firstRowSampleFallback: Vector3 | undefined = undefined;
  let incompleteSamples = 0;

  let no = 0;
  for (const { vectors, edgeVectors, vectorsSampleArea, edgeVectorsSampleArea } of sampleRows) {
    no++;

    values.sampleCount += vectors.length + edgeVectors.length;
    values.area += vectors.length * vectorsSampleArea + edgeVectors.length * edgeVectorsSampleArea;

    const polygonVolume = processSampleVectors(vectors, vectorsSampleArea, model, polygon, firstRowSampleFallback);
    const edgesVolume = processSampleVectors(edgeVectors, edgeVectorsSampleArea, model, edges, firstRowSampleFallback);

    values.volume.above += polygonVolume.volume.above + edgesVolume.volume.above;
    values.volume.below += polygonVolume.volume.below + edgesVolume.volume.below;

    polygonVolume.projections.above.push(...edgesVolume.projections.above);
    polygonVolume.projections.below.push(...edgesVolume.projections.below);

    const projections = {
      above: Float32Array.from(polygonVolume.projections.above),
      below: Float32Array.from(polygonVolume.projections.below),
    };

    const percent = Math.round((no * 100) / sampleRows.length);

    firstRowSampleFallback = polygonVolume.firstProjection?.clone() || undefined;
    incompleteSamples += polygonVolume.incompleteSamples + edgesVolume.incompleteSamples;

    onProgress(percent, precision, projections);
  }

  dispose(edges);

  return {
    area: values.area,
    cut: values.volume.above,
    fill: values.volume.below,
    netFill: values.volume.below - values.volume.above,
    precision: precision,
    sampleCount: values.sampleCount,
    incompleteness: (incompleteSamples * 100) / values.sampleCount,
  };
}

type SampleRow = {
  vectors: Vector3[];
  edgeVectors: [Vector3, Vector3];
  vectorsSampleArea: number;
  edgeVectorsSampleArea: number;
};

/** Calculates and returns samples for given polygon vertices */
export function calculatePolygonSamples(
  vectors: Vector3[],
  sampleSize: number
): { samples: SampleRow[]; precision: number } {
  const SAMPLE = sampleSize;

  raycaster.firstHitOnly = true;
  if (raycaster.params.Line?.threshold) raycaster.params.Line.threshold = 0.00001;

  const min = vectors[0].clone().setY(EVEREST);
  const max = vectors[0].clone().setY(EVEREST);

  const perimeterVertices: Vector3[] = [];

  for (let i = 0; i < vectors.length; i++) {
    const a = vectors[i].clone().setY(EVEREST);
    const b = (vectors[i + 1] ?? vectors[0]).clone().setY(EVEREST);

    const { x, z } = a;

    if (x < min.x) min.setX(x);
    if (z < min.z) min.setZ(z);
    if (x > max.x) max.setX(x);
    if (z < max.z) max.setZ(z);

    perimeterVertices.push(a);
    perimeterVertices.push(b);
  }

  min.setZ(min.z - 0.1);
  max.setZ(max.z - 0.1);

  const { vectors: vectorsA, sampleSize: sampleSizeA } = getVectorsBetween(min, max, SAMPLE);
  const perimeter = new LineSegments(new BufferGeometry().setFromPoints(perimeterVertices));

  const rayDirection = new Vector3(0, 0, 1);

  const samples: SampleRow[] = [];

  for (const rayOrigin of vectorsA) {
    raycaster.set(rayOrigin, rayDirection);
    const perimeterIntersections = raycaster.intersectObject(perimeter);

    for (let i = 0; i < perimeterIntersections.length; i += 2) {
      const a = perimeterIntersections[i]?.point;
      const b = perimeterIntersections[i + 1]?.point;

      if (!a || !b) continue;

      const { vectors, sampleSize: sampleSizeB } = getVectorsBetween(a, b, sampleSizeA);
      const sampleArea = sampleSizeA * sampleSizeB;

      samples.push({
        vectors: vectors,
        edgeVectors: [a, b],
        vectorsSampleArea: sampleArea,
        edgeVectorsSampleArea: sampleArea / 2,
      });
    }
  }

  dispose(perimeter);

  return { samples, precision: sampleSizeA };
}

/** Calculates volume for samples row */
function processSampleVectors(
  vectors: Vector3[],
  area: number,
  model: Group | Mesh,
  polygon: Mesh | LineSegments,
  fallback?: Vector3
) {
  const volume = { above: 0, below: 0 };

  const projections = { above: [] as number[], below: [] as number[] };

  let firstProjection: Vector3 | undefined = undefined;
  let sampleFallback: Vector3 | undefined = fallback;
  let incompleteSamples = 0;

  for (const v of vectors) {
    const sample = calculateSampleVolume(v, area, model, polygon, sampleFallback);

    if (!sample) continue;

    const key = sample.above ? "above" : "below";

    volume[key] += sample.volume;
    projections[key].push(...sample.projection.toArray());

    sampleFallback = sample.projection.clone();
    if (!firstProjection && !sample.usedFallback) firstProjection = sample.projection.clone();
    if (sample.usedFallback) incompleteSamples++;
  }

  return { volume, projections, firstProjection, incompleteSamples };
}

type SampleVolume = { volume: number; projection: Vector3; above: boolean; usedFallback: boolean };

/** Calculates volume of single sample */
export function calculateSampleVolume(
  vector: Vector3,
  area: number,
  model: Group | Mesh,
  polygon: Mesh | LineSegments,
  fallback?: Vector3
): SampleVolume | undefined {
  // Set raycaster
  if (raycaster.params.Line?.threshold) raycaster.params.Line.threshold = 0.001;
  raycaster.set(vector, vectorDown);
  raycaster.firstHitOnly = false;

  // Find model intersections
  const modelIntersections = raycaster.intersectObject(model, true);

  let modelIntersect: Vector3 | undefined = undefined;
  let usedFallback = false;

  if (modelIntersections.length) modelIntersect = modelIntersections[0].point;

  // If no model intersection, use fallback
  if (!modelIntersect && fallback) {
    modelIntersect = vector.clone().setY(fallback.y);
    usedFallback = true;
  }

  // If no model intersection or fallback, skip this sample
  if (!modelIntersect) return undefined;

  let hasObstacles = !usedFallback && modelIntersections.length > 1;

  // If has even count of obstacles, remove penultimate intersection
  if (hasObstacles && modelIntersections.length % 2 === 0) modelIntersections.splice(modelIntersections.length - 2, 1);

  // If has obstacles but only one intersection (after correction), use first intersection
  if (hasObstacles && modelIntersections.length === 1) {
    modelIntersect = modelIntersections[0].point.clone();
    hasObstacles = false;
  }

  // If has obstacles, use last model intersection
  if (hasObstacles) modelIntersect = modelIntersections[modelIntersections.length - 1].point.clone();

  // Intersect polygon
  raycaster.firstHitOnly = true;
  const polygonIntersections = raycaster.intersectObject(polygon, true);

  // If no polygon intersection, skip the sample
  if (!polygonIntersections.length) return undefined;

  const polygonIntersect = polygonIntersections[0].point;

  // Calculate model and polygon intersections distance
  const distance = modelIntersect.distanceTo(polygonIntersect);
  const above = modelIntersect.y >= polygonIntersect.y;

  // If has obstacles, calculate volume sum of all obstacle pairs
  let obstaclesVolume = 0;
  if (hasObstacles) {
    // Exclude final model intersection (used for sample volume)
    modelIntersections.splice(modelIntersections.length - 1, 1);
    for (let i = 0; i < modelIntersections.length; i += 2) {
      const a = modelIntersections[i].point;
      const b = modelIntersections[i + 1].point;

      // Exclude obstacles that aren't between model and polygon
      if (above && (a.y > modelIntersect.y || a.y < polygonIntersect.y)) continue;
      if (!above && (a.y < modelIntersect.y || a.y > polygonIntersect.y)) continue;

      obstaclesVolume += a.distanceTo(b) * area;
    }
  }

  const volume = distance * area - obstaclesVolume;

  return { volume, projection: modelIntersect, above, usedFallback };
}

/** Creates polygon edges as LineSegments object */
export function createPolygonEdgeLines(vertices: Vector3[]): LineSegments {
  const edgeVertices: Vector3[] = [];

  for (let i = 0; i < vertices.length; i++) {
    edgeVertices.push(vertices[i].clone());
    edgeVertices.push((vertices[i + 1] ?? vertices[0]).clone());
  }

  return new LineSegments(new BufferGeometry().setFromPoints(edgeVertices));
}

/** Calculates sample size based on polygon and user settings */
export function calculateSampleSize(polygonArea: number, samplesPerSecond: number, targetTime = 1): number {
  const a = parseFloat(Math.sqrt(polygonArea / (samplesPerSecond * targetTime)).toFixed(5));
  return a < MIN_SAMPLE_SIZE ? MIN_SAMPLE_SIZE : a;
}
