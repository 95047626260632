import { h, Fragment } from "preact";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import { Icon, IconType } from "components/Icon";
import { InfoType } from "components/Panel/Footer/useInfo";
import { CsvExport } from "components/Panel/Footer/CsvExport";
import { useViewerMeasurement } from "hooks";
import { useLocale } from "locale";
import { useUIStore } from "store";
import { isMac } from "utils/device";
import "./styles.css";

interface Props {
  type: InfoType;
}

export const Info = ({ type }: Props) => {
  const { t } = useLocale();
  const { controlMode } = useUIStore();
  const { modelType, coordinateSystem } = useViewerMeasurement();

  const modelRef = useRef<HTMLDivElement>(null);
  const measurementRef = useRef<HTMLDivElement>(null);
  const exportRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const modelTypeValue = useMemo(() => {
    if (modelType === "scale") return t("modelType.scale");
    if (modelType === "georef_maps") return t("modelType.georefMaps");
    if (modelType === "georef_spray") return t("modelType.georefSpray");
    if (modelType === "georef_qr") return t("modelType.georefQr");
    return t("modelType.simple");
  }, [t, modelType]);

  const modelTypeIcon = useMemo((): IconType => {
    if (modelType === "scale") return "modelScale";
    if (modelType === "georef_maps") return "modelGMaps";
    if (modelType === "georef_spray") return "modelGSpray";
    if (modelType === "georef_qr") return "modelGQr";
    return "modelSimple";
  }, [modelType]);

  useEffect(() => {
    if (!type) return setHeight(0);
    if (type === "model") return setHeight(modelRef.current?.clientHeight || 0);
    if (type === "measurement") return setHeight(measurementRef.current?.offsetHeight || 0);
    if (type === "export") return setHeight(exportRef.current?.offsetHeight || 0);
  }, [type, controlMode]);

  const visible = { opacity: 1 };
  const hidden = { opacity: 0, pointerEvents: "none" };

  return (
    <div class="panel-footer__info" style={{ height }}>
      {/** Model info */}
      <div ref={modelRef} class="panel-footer__info-content" style={type === "model" ? visible : hidden}>
        <MeasurementRow icons={[modelTypeIcon]}>
          {modelTypeValue + (coordinateSystem ? `\n(${coordinateSystem})` : "")}
        </MeasurementRow>
      </div>

      {/** Measurement help */}
      <div ref={measurementRef} class="panel-footer__info-content" style={type === "measurement" ? visible : hidden}>
        {!controlMode && <MeasurementRow icons={["mouseLeft"]}>{t("panel.footer.info.activate")}</MeasurementRow>}

        {controlMode === "add" && (
          <Fragment>
            <MeasurementRow icons={["mouseLeft"]}>{t("panel.footer.info.add")}</MeasurementRow>
            <MeasurementRow icons={["mouseRight"]}>{t("panel.footer.info.deactivate")}</MeasurementRow>
          </Fragment>
        )}

        {controlMode !== "edit" && (
          <MeasurementRow icons={isMac ? ["command"] : ["ctrl"]}>
            {t(isMac ? "panel.footer.info.commandToEdit" : "panel.footer.info.controlToEdit")}
          </MeasurementRow>
        )}

        {controlMode === "edit" && (
          <Fragment>
            <MeasurementRow icons={["mouseLeft"]}>{t("panel.footer.info.confirmEdit")}</MeasurementRow>
            <MeasurementRow icons={["mouseRight", "esc"]}>{t("panel.footer.info.cancelEdit")}</MeasurementRow>
          </Fragment>
        )}
      </div>

      {/** Export */}
      <div ref={exportRef} class="panel-footer__info-content" style={type === "export" ? visible : hidden}>
        <CsvExport />
      </div>
    </div>
  );
};

interface MeasurementRowProps {
  icons: IconType[];
  children: string;
}

const MeasurementRow = ({ icons, children }: MeasurementRowProps) => {
  return (
    <div class="panel-footer__info-content-m-row">
      {icons.map((type) => (
        <div key={type} class="panel-footer__info-content-m-row-icon flex-center">
          <Icon type={type} />
        </div>
      ))}

      <span class="panel-footer__info-content-m-row-text">{children}</span>
    </div>
  );
};
