import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import "./styles.css";
import { IconComponent, IconComponentsType } from "components/Icons/IconComponent";

interface Props<T> {
  icon: IconComponentsType;
  value?: T;
  title?: string;
  active?: boolean;
  showText?: boolean;
  onClick?: (value?: T) => any;
}

export const Button = <T extends string | undefined>({
  icon,
  value,
  title,
  active = false,
  showText = false,
  onClick = () => {},
}: Props<T>) => {
  const [hovered, setHovered] = useState(false);
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(value);
    },
    [onClick, value]
  );

  const mouseEnter = () => {
    setHovered(true);
  };

  const mouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      class={`toolbar__button ${showText ? "toolbar__button--text" : ""} ${active ? "toolbar__button--active" : ""}`}
      title={title}
      onClick={handleClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <IconComponent type={icon} color={active ? "#ffffff" : hovered ? "#00377b" : "#6C6D75"} width={20} height={20} />
      {showText && <span>{title}</span>}
    </div>
  );
};
