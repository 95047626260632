import { useCallback, useContext, useMemo } from "preact/hooks";
import { UIStoreContext } from "store/UI/index";
import { Mode, viewer } from "viewer";

export const useUIStore = () => {
  const store = useContext(UIStoreContext);

  const hasMode = useMemo(() => store.mode !== undefined, [store.mode]);

  const requestModeChange = useCallback(
    (mode?: Mode) => {
      if (!store.warningPrevented && viewer.measurement.hasCoordinates()) {
        store.setRequestedMode(mode);
        store.setWarning(true);
        return;
      }

      store.setMode(mode);
    },
    [store]
  );

  const error = useMemo(() => store.error, [store.error]);

  return { ...store, error, hasMode, requestModeChange };
};
