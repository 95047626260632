import { h, Fragment } from "preact";
import { Icon, IconType } from "components/Icon";
import { Button } from "components/Button";
import { LoaderErrors } from "viewer";
import { useLocale } from "locale";
import "./styles.css";

interface Props {
  error: LoaderErrors;
}

export const ApiError = ({ error }: Props) => {
  const { t } = useLocale();

  const apiError = [LoaderErrors.api_error, LoaderErrors.missing_model].includes(error);

  const icon: IconType = apiError ? "apiError" : "modelError";

  const reload = () => window.location.reload();

  return (
    <div class="api-error">
      <div class="api-error__scroll">
        <section class="api-error__content">
          <div class="api-error__content-img-container">
            <Icon type={icon} />
          </div>
          <div class="api-error__content-info-container">
            <div class="api-error__content-info">
              <p class="text-500 text-purple-200 api-error__content-heading">
                {splitHeading(apiError ? t("errors.apiErrorHeading") : t("errors.modelErrorHeading"))}
              </p>

              {apiError && <p class="text-purple-200">{t("errors.apiErrorText")}</p>}

              {error === LoaderErrors.loading_model_failed && (
                <Fragment>
                  <p class="text-purple-200">
                    {t("errors.modelErrorTryReload")}
                    <br />
                    {t("errors.modelErrorReasons")}
                  </p>

                  <ul class="text-purple-200 api-error__list">
                    <li class="api-error__list-item">
                      {t("errors.browserReason")}
                      <br />
                      {t("errors.browserHint")}
                    </li>
                    <li class="api-error__list-item">
                      {t("errors.resourcesReason")}
                      <br />
                      {t("errors.resourcesHint")}
                    </li>
                    <li class="api-error__list-item">
                      {t("errors.deviceReason")}
                      <br />
                      {t("errors.deviceHint")}
                    </li>
                    <li class="api-error__list-item">{t("errors.corruptedReason")}</li>
                  </ul>

                  <Button size={"lg"} onClick={reload}>
                    {t("errors.reloadModel")}
                  </Button>
                </Fragment>
              )}

              {error === LoaderErrors.model_not_supported && (
                <Fragment>
                  <p class="text-purple-200 mb-20">{t("errors.formatErrorReason")}</p>
                </Fragment>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const splitHeading = (value: string) => {
  const lines = value.split("...");

  if (lines.length === 1) return value;

  return (
    <Fragment>
      {lines[0]}...
      <br />
      {lines[1]}
    </Fragment>
  );
};
