import { h } from "preact";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import { Section } from "components/Panel/Settings/Section";
import { RadioBox } from "components/Panel/Settings/RadioBox";
import { ButtonBar } from "components/Panel/Settings/ButtonBar";
import { Altitude } from "components/Panel/Settings/Altitude";
import { useVolumeStore, useValuesStore } from "store";
import { round } from "utils/format";
import { useLocale } from "locale";
import "./styles.css";

type Method = "smart" | "horizontal";
type Precision = 0.5 | 1 | 4 | 9;

interface Props {
  active: boolean;
}

export const Settings = ({ active }: Props) => {
  const { t } = useLocale();
  const { height } = useValuesStore();
  const { method, precision, y, disabled, setVolume } = useVolumeStore();

  const [altitudeTouched, setAltitudeTouched] = useState(false);

  const minY = useMemo(() => round(height.min), [height.min]);
  const maxY = useMemo(() => round(height.max), [height.max]);

  const handleChangeMethod = useCallback((method: Method) => setVolume({ method, y: minY }), [setVolume, minY]);

  const handleChangePrecision = useCallback((precision: number) => setVolume({ precision }), [setVolume]);

  const handleChangeAltitude = useCallback(
    (y: number) => {
      setAltitudeTouched(true);
      setVolume({ y });
    },
    [setVolume]
  );

  useEffect(() => {
    if (altitudeTouched) return;

    setVolume({ y: minY });
  }, [altitudeTouched, setVolume, minY]);

  if (!active) return null;

  return (
    <div class="settings__container">
      <Section heading={t("panel.settings.method.heading")} description={t("panel.settings.method.description")}>
        <div class="settings__radio-box-group">
          <RadioBox<Method>
            value={"smart"}
            icon={"polygonSmart"}
            label={t("panel.settings.method.smart.label")}
            description={t("panel.settings.method.smart.description")}
            active={method === "smart"}
            disabled={disabled}
            onClick={handleChangeMethod}
          />
          <RadioBox<Method>
            value={"horizontal"}
            icon={"polygonHorizontal"}
            label={t("panel.settings.method.horizontal.label")}
            description={t("panel.settings.method.horizontal.description")}
            active={method === "horizontal"}
            disabled={disabled}
            onClick={handleChangeMethod}
          />
        </div>
      </Section>

      <Section heading={t("panel.settings.precision.heading")} description={t("panel.settings.precision.description")}>
        <ButtonBar<Precision>
          items={[
            { value: 0.5, label: `${t("panel.settings.precision.low")} (<1s)` },
            { value: 1, label: `${t("panel.settings.precision.medium")} (~1s)` },
            { value: 4, label: `${t("panel.settings.precision.high")} (~4s)` },
            { value: 9, label: `${t("panel.settings.precision.veryHigh")} (~9s)` },
          ]}
          disabled={disabled}
          selected={precision as Precision}
          onChange={handleChangePrecision}
        />
      </Section>

      {method === "horizontal" && (
        <Section heading={t("panel.settings.altitude.heading")}>
          <Altitude value={y} min={minY} max={maxY} disabled={disabled} onChange={handleChangeAltitude} />
        </Section>
      )}
    </div>
  );
};
