import { ComponentChildren, h } from "preact";
import "./styles.css";

interface Props {
  children: ComponentChildren;
  heading: string;
  description?: string;
}

export const Section = ({ children, heading, description }: Props) => (
  <div class="settings__section">
    <p class="text-medium">{heading}</p>

    {description && <p class="text-gray-800">{description}</p>}

    <div class="settings__section-content-container">{children}</div>
  </div>
);
