import { ComponentChildren, createContext, h } from "preact";
import { StateUpdater, useState } from "preact/hooks";
import { ConversionUnit } from "utils/format";

function stateUpdater<T>(): StateUpdater<T> {
  return () => {};
}

const DEFAULT_VALUE = {
  unit: "m" as ConversionUnit,
  setUnit: stateUpdater<ConversionUnit>(),
};

export const UnitsStoreContext = createContext(DEFAULT_VALUE);

interface Props {
  children: ComponentChildren;
}

export const UnitsStore = ({ children }: Props) => {
  const [unit, setUnit] = useState(DEFAULT_VALUE.unit);

  const store = { unit, setUnit };

  return <UnitsStoreContext.Provider value={store}>{children}</UnitsStoreContext.Provider>;
};
