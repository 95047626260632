import { Viewer } from "viewer/Viewer";
import { initMeshBvh } from "viewer/utils";
import { LoaderErrors } from "viewer/core";
import { Values, Mode, ControlMode } from "viewer/measurement/interface";

initMeshBvh();

const viewer = new Viewer();

export { viewer, Values, Mode, ControlMode, LoaderErrors };
