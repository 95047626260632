import { h } from "preact";
import { useUIStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

const year = new Date().getFullYear();

export const Footer = () => {
  const { t } = useLocale();
  const { hasMode: isPanelOpen } = useUIStore();

  return (
    <footer class={`footer ${isPanelOpen ? "footer--panel-open" : ""}`}>
      <span class="text-gray-700 text-100 text-center">
        {t("footer.prefix")} <span class="text-purple-600">{t("footer.mawisEu")}</span>
        {` / ©${year} `}
        <span class="text-purple-600">{t("footer.company")}</span>
      </span>
    </footer>
  );
};
