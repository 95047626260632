import { Fragment, h } from "preact";
import { useMemo } from "preact/hooks";
import { UnitSelect } from "components/Panel/Overview/UnitSelect";
import { useUIStore, useVolumeStore, useUnitsStore, useValuesStore } from "store";
import { addThousandsSeparator, convertFormattedValue, Unit } from "utils/format";
import { useViewerMeasurement } from "hooks";
import { useLocale } from "locale";
import "./styles.css";

export const Overview = () => {
  const { t } = useLocale();
  const { mode } = useUIStore();
  const { length, area, perimeter, height } = useValuesStore();
  const { values: volume, precision: precisionSettings } = useVolumeStore();
  const { inScale } = useViewerMeasurement();

  const showDistance = mode === "distance";
  const showVolume = mode === "volume";
  const showArea = mode === "area" || mode === "volume";

  const precision = useMemo(() => {
    if (!showVolume) return "";

    let prefix = "-";

    if (precisionSettings === 0.5) prefix = t("panel.settings.precision.low");
    if (precisionSettings === 1) prefix = t("panel.settings.precision.medium");
    if (precisionSettings === 4) prefix = t("panel.settings.precision.high");
    if (precisionSettings === 9) prefix = t("panel.settings.precision.veryHigh");

    if (!volume.precision) return prefix;

    return `${prefix}; ${t("common.square")} ${Math.round(volume.precision * 1000)} mm`;
  }, [showVolume, precisionSettings, t, volume.precision]);

  return (
    <div class="panel-overview__container">
      <div class="panel-overview__header">
        <span class="text-medium">{t("panel.overview.heading")}</span>
        {inScale && <UnitSelect />}
      </div>
      <table class="panel-overview__table">
        {showDistance && (
          <Fragment>
            <Row label={t("panel.overview.realDistance")} value={length.real} s={inScale} />
            <Row label={t("panel.overview.projectedDistance")} value={length.projected} s={inScale} />
          </Fragment>
        )}

        {showVolume && (
          <Fragment>
            <Row label={t("panel.overview.volumeBelow")} value={volume.fill} s={inScale} u={"m³"} p={3} />
            <Row label={t("panel.overview.volumeAbove")} value={volume.cut} s={inScale} u={"m³"} p={3} />
            <Row label={t("panel.overview.volumeDiff")} value={volume.netFill} s={inScale} u={"m³"} p={3} />
            <Row label={t("panel.overview.incompleteness")} value={volume.incompleteness} s={true} u={"%"} />
            <Row label={t("panel.overview.precision")} text={precision} />
          </Fragment>
        )}

        {showArea && (
          <Fragment>
            <Row label={t("panel.overview.realArea")} value={area.real} s={inScale} u={"m²"} />
            <Row label={t("panel.overview.projectedArea")} value={area.projected} s={inScale} u={"m²"} />
            <Row label={t("panel.overview.realPerimeter")} value={perimeter.real} s={inScale} />
            <Row label={t("panel.overview.projectedPerimeter")} value={perimeter.projected} s={inScale} />
          </Fragment>
        )}

        <Row label={t("panel.overview.minAltitude")} value={height.min} s={inScale} />
        <Row label={t("panel.overview.maxAltitude")} value={height.max} s={inScale} />
        <Row label={t("panel.overview.altitudeDiff")} value={height.diff} s={inScale} />
      </table>
    </div>
  );
};

interface RowProps {
  label: string;
  value?: number;
  text?: string;
  u?: Unit; // Unit
  s?: boolean; // In scale
  p?: number; // Precision
}

const Row = ({ label, value, text, u = "m", s = false, p = 2 }: RowProps) => {
  const { unit: toUnit } = useUnitsStore();

  return (
    <tr>
      <td>{label}:</td>
      <td class="text-right">{text || addThousandsSeparator(convertFormattedValue(value, s, u, toUnit, p))}</td>
    </tr>
  );
};
