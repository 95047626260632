import { Group, Mesh, Raycaster, Vector2, Vector3 } from "three";
import * as I from "viewer/measurement/interface";
import { renderer } from "viewer/core/renderer";
import { camera } from "viewer/core/camera";
import { isMac } from "utils/device";

export class Raycasting {
  private readonly raycaster: Raycaster = new Raycaster();
  private readonly mouse: I.MousePosition = { current: new Vector2(), previous: new Vector2() };

  private readonly onLeftClick: I.LeftClickHandler;
  private readonly onLeftClickWithCommand: I.LeftClickWithCommandHandler;
  private readonly onRightClick: I.RightClickHandler;

  private click?: PointerEvent;

  private model?: Group;

  public position?: Vector3;

  constructor(
    onLeftClick: I.LeftClickHandler,
    onLeftClickWithCommand: I.LeftClickWithCommandHandler,
    onRightClick: I.RightClickHandler
  ) {
    this.onLeftClick = onLeftClick;
    this.onLeftClickWithCommand = onLeftClickWithCommand;
    this.onRightClick = onRightClick;

    // Performance optimization - return intersections on first mesh hit
    this.raycaster.firstHitOnly = true;

    this.registerListeners();
  }

  public setModel(model: Group): void {
    this.model = model;
  }

  /** Ray-casts mouse cursor with model */
  public raycastModel(force = false): Vector3 | undefined {
    if (!this.model) return;

    // Mouse didn't move
    if (!force && this.mouse.current.equals(this.mouse.previous)) return this.position;

    this.mouse.previous.copy(this.mouse.current);

    this.raycaster.setFromCamera(this.mouse.current, camera.active);

    const intersections = this.raycaster.intersectObject(this.model, true);

    this.position = intersections.length > 0 ? intersections[0].point : undefined;

    return this.position?.clone();
  }

  /** Ray-casts mouse cursor with dots meshes and returns index of hit dot */
  public intersectMeshes(meshes: Mesh[]): number | undefined {
    this.raycaster.setFromCamera(this.mouse.current, camera.active);

    for (let i = 0; i < meshes.length; i++) {
      if (this.raycaster.intersectObject(meshes[i], true).length) return i;
    }

    return undefined;
  }

  /** Checks whether provided vector equals last position */
  public positionEquals(position?: Vector3): boolean {
    if (position && this.position && position.equals(this.position)) return true;
    return !position && !this.position;
  }

  /** Removes listeners */
  public cleanup(): void {
    this.removeListeners();
  }

  /** Mouse move handler - sets last mouse cursor position  */
  private handleMouseMove = (e: PointerEvent): void => {
    this.mouse.current.x = (e.clientX / renderer.canvasWidth) * 2 - 1;
    this.mouse.current.y = -(e.clientY / renderer.canvasHeight) * 2 + 1;
  };

  /** Mouse down handler - if active, sets last click position and button */
  private handleMouseDown = (e: PointerEvent): void => {
    this.click = e;
  };

  /** Mouse up handler - if valid (mouse position did not change), call click callbacks */
  private handleMouseUp = (e: PointerEvent): void => {
    if (!this.click) return;

    const diffX = Math.abs(this.click.clientX - e.clientX);
    const diffY = Math.abs(this.click.clientY - e.clientY);
    const mouseMoved = diffX > 1 || diffY > 1;

    const leftClick = this.click.buttons === 1;
    const rightClick = this.click.buttons === 2;
    const command = isMac ? this.click.metaKey : this.click.ctrlKey;

    this.click = undefined;

    if (mouseMoved) return;

    if (leftClick && command) return this.onLeftClickWithCommand(this.position?.clone());
    if (leftClick) return this.onLeftClick(this.position?.clone());
    if (rightClick) return this.onRightClick();
  };

  /** Registers mouse event listeners */
  private registerListeners(): void {
    renderer.domElement?.addEventListener("pointermove", this.handleMouseMove, false);
    renderer.domElement?.addEventListener("pointerdown", this.handleMouseDown, false);
    renderer.domElement?.addEventListener("pointerup", this.handleMouseUp, false);
  }

  /** Removes mouse event listeners */
  private removeListeners(): void {
    renderer.domElement?.removeEventListener("pointermove", this.handleMouseMove, false);
    renderer.domElement?.removeEventListener("pointerdown", this.handleMouseDown, false);
    renderer.domElement?.removeEventListener("pointerup", this.handleMouseUp, false);
  }
}
