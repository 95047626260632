import i18next, { TOptions } from "i18next";
import { useContext } from "preact/hooks";
import { TKeys } from "locale/keys/keys";
import { Context } from "locale/Locale";

const _t = i18next.t.bind(i18next);

export const t = (key: TKeys | TKeys[], options: TOptions = {}) => _t(key, options);

export const useLocale = () => {
  const { locale, setLocale } = useContext(Context);

  return { t, locale, setLocale };
};
