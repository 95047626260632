import { useCallback, useEffect, useState } from "preact/hooks";

export const useModal = (onEsc: () => any = () => {}) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const handleEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== "Escape") return;

      e.preventDefault();
      e.stopPropagation();

      close();
      onEsc();
    },
    [close, onEsc]
  );

  const addListener = useCallback(() => window.addEventListener("keyup", handleEsc, false), [handleEsc]);
  const removeListener = useCallback(() => window.removeEventListener("keyup", handleEsc, false), [handleEsc]);

  useEffect(() => {
    isOpen ? addListener() : removeListener();
    return removeListener;
  }, [isOpen, addListener, removeListener]);

  return { isOpen, open, close };
};
