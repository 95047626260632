import { Fragment, h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Tabs } from "components/Tabs";
import { Coords } from "components/Panel/Coords";
import { Lines } from "components/Panel/Lines";
import { Overview } from "components/Panel/Overview";
import { useLocale } from "locale";
import "./styles.css";

type TabKeys = "values" | "coords";

export const Distance = () => {
  const { t } = useLocale();
  const [activeTab, setActiveTab] = useState<TabKeys>("values");

  const handleClickTab = useCallback((key: TabKeys) => setActiveTab(key), []);

  return (
    <Fragment>
      <Tabs<TabKeys>
        tabs={[
          { key: "values", label: t("panel.tabs.values") },
          { key: "coords", label: t("panel.tabs.coords") },
        ]}
        activeTab={activeTab}
        onClick={handleClickTab}
      />

      {activeTab === "values" && <Lines />}
      {activeTab === "coords" && <Coords />}

      {activeTab === "values" && <Overview />}
    </Fragment>
  );
};
