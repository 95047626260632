import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "components/Link";
import { getCoordinateName } from "utils/coordinates";
import { useViewerControls, useViewerMeasurement } from "hooks";
import { useUIStore, useUnitsStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export const CsvExport = () => {
  const { t, locale } = useLocale();

  const { downloadCoordsCsv, downloadValuesCsv } = useViewerControls();
  const { coordinateSystem } = useViewerMeasurement();
  const { unit: conversion } = useUnitsStore();
  const { mode } = useUIStore();

  const handleClickExportCoords = useCallback(() => {
    downloadCoordsCsv(
      {
        point: t("common.point"),
        x: getCoordinateName("X", locale),
        y: getCoordinateName("Y", locale),
        z: getCoordinateName("Z", locale),
        coordinateSystem: t("common.coordinateSystem"),
        code: t("common.code"),
      },
      coordinateSystem
    );
  }, [coordinateSystem, downloadCoordsCsv, locale, t]);

  const handleClickExportValues = useCallback(() => {
    downloadValuesCsv(
      {
        lengthReal: t("panel.overview.realDistance"),
        lengthProjected: t("panel.overview.projectedDistance"),
        volumeFill: t("panel.overview.volumeBelow"),
        volumeCut: t("panel.overview.volumeAbove"),
        volumeNetFill: t("panel.overview.volumeDiff"),
        volumeIncompleteness: t("panel.overview.incompleteness"),
        precision: t("panel.overview.precision"),
        areaReal: t("panel.overview.realArea"),
        areaProjected: t("panel.overview.projectedArea"),
        perimeterReal: t("panel.overview.realPerimeter"),
        perimeterProjected: t("panel.overview.projectedPerimeter"),
        heightMin: t("panel.overview.minAltitude"),
        heightMax: t("panel.overview.maxAltitude"),
        heightDiff: t("panel.overview.altitudeDiff"),
      },
      {
        measurement: "",
        values: t("panel.overview.heading"),
      },
      conversion
    );
  }, [conversion, downloadValuesCsv, t]);

  return (
    <div class="panel-footer__export">
      <Link icon={"download"} padding={false} onClick={handleClickExportCoords}>
        {t("panel.footer.export.csvCoordinates")}
      </Link>

      {mode !== "coords" && (
        <Link icon={"download"} padding={false} onClick={handleClickExportValues}>
          {t("panel.footer.export.csvValues")}
        </Link>
      )}
    </div>
  );
};
