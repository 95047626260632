import { BufferGeometry, Vector3 } from "three";
import * as I from "viewer/workers/interface";

import { default as VolumeWorker } from "worker-loader!./volume.worker";

type VolumeResultHandler = (data: I.Volume) => any;
type VolumeProgressHandler = (data: I.Progress) => any;

export class WorkersController {
  private volumeWorker: Worker = new VolumeWorker();
  private geometry?: BufferGeometry;

  private onVolumeProgress: VolumeProgressHandler = () => {};
  private onVolumeResult: VolumeResultHandler = () => {};

  constructor() {
    this.registerListener();
  }

  public loadGeometry(data?: BufferGeometry): void {
    if (data) this.geometry = data;

    if (!this.geometry) return;

    this.postMessage({ type: I.InputMessageType.geometry, input: WorkersController.composeGeometry(this.geometry) });
  }

  public calculateVolume(
    vertices: Vector3[],
    sampleSize: number,
    onProgress: VolumeProgressHandler,
    onSuccess: VolumeResultHandler
  ): void {
    this.onVolumeProgress = onProgress;
    this.onVolumeResult = onSuccess;

    this.postMessage({ type: I.InputMessageType.calculate, input: { vertices, sampleSize } });
  }

  public abort(reopen = true): void {
    this.volumeWorker.terminate();

    if (!reopen) return;

    this.volumeWorker = new VolumeWorker();
    this.registerListener();
    this.loadGeometry();
  }

  public test(
    model: Vector3[],
    vertices: Vector3[],
    sampleSize: number,
    onProgress: VolumeProgressHandler,
    onSuccess: VolumeResultHandler
  ): void {
    this.onVolumeProgress = onProgress;
    this.onVolumeResult = onSuccess;

    this.postMessage({ type: I.InputMessageType.test, input: { model, vertices, sampleSize } });
  }

  private postMessage(message: I.InputMessage, transfer: Transferable[] = []): void {
    this.volumeWorker.postMessage(message, transfer);
  }

  private handleMessage = ({ data }: MessageEvent<I.OutputMessage>): void => {
    switch (data.type) {
      case I.OutputMessageType.progress:
        return this.onVolumeProgress(data.output);
      case I.OutputMessageType.volume:
        return this.onVolumeResult(data.output);
    }
  };

  private registerListener(): void {
    this.volumeWorker.addEventListener("message", this.handleMessage);
  }

  private static composeGeometry(geometry: BufferGeometry): I.Geometry {
    return {
      position: geometry.attributes.position.array as Float32Array,
      index: geometry.getIndex()?.array as Uint32Array,
    };
  }
}
