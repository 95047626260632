import { ComponentChildren, createContext, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { Values, viewer } from "viewer";

export { Values };

const DEFAULT_VALUE = viewer.measurement.values.getValues();

export const ValuesStoreContext = createContext(DEFAULT_VALUE);

interface Props {
  children: ComponentChildren;
}

export const ValuesStore = ({ children }: Props) => {
  const [values, setValues] = useState(DEFAULT_VALUE);

  useEffect(() => viewer.measurement.setValuesChangeHandler(setValues), []);

  return <ValuesStoreContext.Provider value={values}>{children}</ValuesStoreContext.Provider>;
};
