import { h } from "preact";
import { Locale } from "locale";
import { StoreProvider } from "store";
import "styles/global.css";
import { Layout } from "components";

// Import global styles before importing other components to prevent css overwrite

export const App = () => (
  <Locale>
    <StoreProvider>
      <Layout />
    </StoreProvider>
  </Locale>
);
