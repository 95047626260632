import * as Sentry from "@sentry/browser";
// import { getEnv } from "utils/env";

// Sentry.init({
//   dsn: getEnv("SENTRY_DSN"),
//   release: getEnv("SENTRY_RELEASE"),
//   enabled: getEnv("NODE_ENV") === "production",
//   environment: window.location.hostname,
// });

export const logException = (exception: any) => {
  try {
    Sentry.captureException(exception);
    console.error(exception);
  } catch (e) {}
};
