import { h } from "preact";
import { Link } from "components/Link";
import { useVolumeStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export const Loading = () => {
  const { t } = useLocale();
  const { loadingPercent, abort } = useVolumeStore();

  const width = `${loadingPercent}%`;

  return (
    <div class="panel-footer__controls-loading">
      <div class="panel-footer__controls-loading-bar">
        <div class="panel-footer__controls-loading-bar-progress" style={{ width }} />
      </div>
      <div class="panel-footer__controls-loading-footer">
        <span class="text-gray-800">
          {t("panel.footer.calculating")} ({width})
        </span>
        <Link padding={false} onClick={abort}>
          {t("panel.footer.cancelCalculation")}
        </Link>
      </div>
    </div>
  );
};
