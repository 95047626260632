import { h, JSX } from "preact";
import { useCallback, useRef } from "preact/hooks";
import { Icon, IconType } from "components/Icon";
import { round } from "utils/format";
import "./styles.css";

type PreactInputEvent = JSX.TargetedEvent<HTMLInputElement, Event>;

interface Props {
  value: number;
  min: number;
  max: number;
  step?: number;
  disabled?: boolean;
  onChange: (value: number) => any;
}

export const Altitude = ({ value, min, max, step = 0.1, disabled = false, onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const increment = useCallback(() => !disabled && onChange(round(value + step)), [value, step, disabled, onChange]);

  const decrement = useCallback(() => !disabled && onChange(round(value - step)), [value, step, disabled, onChange]);

  const setLowestAltitude = useCallback(() => !disabled && onChange(min), [disabled, min, onChange]);

  const setHighestAltitude = useCallback(() => !disabled && onChange(max), [disabled, max, onChange]);

  const handleInput = useCallback(
    (e: PreactInputEvent) => {
      const currentValue = e.currentTarget.valueAsNumber;
      !disabled && !isNaN(currentValue) && onChange(round(currentValue));
    },
    [disabled, onChange]
  );

  return (
    <div class={`altitude__container ${disabled ? "disabled" : ""}`}>
      <div class="altitude__input-container">
        <input
          ref={inputRef}
          type="number"
          step={0.01}
          value={value}
          disabled={disabled}
          class="altitude__input"
          onInput={handleInput}
        />

        <div class="altitude__input-buttons-container">
          <div class={`altitude__input-button ${disabled ? "disabled" : ""}`} onClick={increment}>
            <Icon type={!disabled ? "angleUp" : "angleUpDisabled"} />
          </div>
          <div class={`altitude__input-button ${disabled ? "disabled" : ""}`} onClick={decrement}>
            <Icon type={!disabled ? "angleDown" : "angleDownDisabled"} />
          </div>
        </div>
      </div>

      <div class="altitude__buttons-container">
        <Button
          icon={!disabled ? "arrowDown" : "arrowDownDisabled"}
          label={`${min.toFixed(3)} m`}
          disabled={disabled}
          onClick={setLowestAltitude}
        />
        <Button
          icon={!disabled ? "arrowUp" : "arrowUpDisabled"}
          label={`${max.toFixed(3)} m`}
          disabled={disabled}
          onClick={setHighestAltitude}
        />
      </div>
    </div>
  );
};

interface ButtonProps {
  icon: IconType;
  label: string;
  disabled?: boolean;
  onClick: () => any;
}

const Button = ({ icon, label, disabled = false, onClick }: ButtonProps) => {
  return (
    <button class="altitude__button" disabled={disabled} onClick={onClick}>
      <Icon type={icon} />
      {label}
    </button>
  );
};
