import { h, Fragment } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Button } from "components/Toolbar/Button";
import { useViewerControls } from "hooks";
import { useUIStore } from "store";
import { useLocale } from "locale";
import { Mode } from "viewer";
import "./styles.css";

export const Toolbar = () => {
  const { t } = useLocale();
  const { hasMode, mode, requestModeChange, help, setHelp } = useUIStore();
  const { capture } = useViewerControls();

  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const isGroupActive = hasMode || isGroupOpen;

  const toggleGroup = useCallback(() => setIsGroupOpen((isGroupOpen) => !isGroupOpen), []);
  const changeMode = useCallback(
    (m?: Mode) => requestModeChange(mode !== m ? m : undefined),
    [mode, requestModeChange]
  );

  const showHelp = useCallback(() => setHelp(true), [setHelp]);

  return (
    <Fragment>
      <div class="toolbar">
        <div class="toolbar__button-group">
          <Button icon={"ruler"} title={t("buttons.measurement")} active={isGroupActive} onClick={toggleGroup} />

          {isGroupOpen && (
            <div class="toolbar__button-group">
              <Button<Mode>
                icon={"axis"}
                value={"coords"}
                title={t("buttons.coordinates")}
                active={mode === "coords"}
                onClick={changeMode}
              />
              <Button<Mode>
                icon={"distance"}
                value={"distance"}
                title={t("buttons.distance")}
                active={mode === "distance"}
                onClick={changeMode}
              />
              <Button<Mode>
                icon={"area"}
                value={"area"}
                title={t("buttons.area")}
                active={mode === "area"}
                onClick={changeMode}
              />
              <Button<Mode>
                icon={"volume"}
                value={"volume"}
                title={t("buttons.volume")}
                active={mode === "volume"}
                onClick={changeMode}
              />
            </div>
          )}
        </div>
      </div>

      <div class="toolbar toolbar--second">
        <Button icon={"questionMark"} title={t("buttons.help")} active={help} onClick={showHelp} />
        <Button icon={"camera"} title={t("buttons.screenshot")} onClick={capture} />
      </div>
    </Fragment>
  );
};
