import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import { useUIStore, useValuesStore } from "store";

export type InfoType = "model" | "measurement" | "export" | undefined;

export const useInfo = () => {
  const { mode } = useUIStore();
  const { coordinates } = useValuesStore();

  const [touched, setTouched] = useState(false);
  const [type, setType] = useState<InfoType>("measurement");

  const visible = useMemo(() => type !== undefined, [type]);

  const toggleModelInfo = useCallback(() => {
    setType((panel) => (panel !== "model" ? "model" : undefined));
    setTouched(true);
  }, []);

  const toggleMeasurementInfo = useCallback(() => {
    setType((panel) => (panel !== "measurement" ? "measurement" : undefined));
    setTouched(true);
  }, []);

  const toggleExport = useCallback(() => {
    setType((panel) => (panel !== "export" ? "export" : undefined));
    setTouched(true);
  }, []);

  // If user didn't interact with FooterInfo, hide automatically after third coordinate is placed
  useEffect(() => {
    if (!touched && type === "measurement" && coordinates.length > 3) setType(undefined);
  }, [coordinates.length, touched, type]);

  // On mode change, show help again
  useEffect(() => {
    setTouched(false);
    setType("measurement");
  }, [mode]);

  return { visible, type, toggleModelInfo, toggleMeasurementInfo, toggleExport };
};
