import { h } from "preact";
import { Button } from "components/Toolbar/Button";
import { useViewerMeasurement } from "hooks";
import { useUIStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export const Mobile = () => {
  const { t } = useLocale();
  const { simulateRightClick } = useViewerMeasurement();
  const { controlMode } = useUIStore();

  const isVisible = controlMode === "add" || controlMode === "edit";

  if (!isVisible) return null;

  return (
    <div class="mobile">
      <Button
        icon={"rulerCrossedOut"}
        title={t("buttons.measurementEnd")}
        showText={true}
        onClick={simulateRightClick}
      />
    </div>
  );
};
