import { h } from "preact";
import { Icon } from "components/Icon";
import { Input } from "components/Panel/Input";
import { useViewerMeasurement } from "hooks";
import { formatValue } from "utils/format";
import { getCoordinateName } from "utils/coordinates";
import { useVolumeStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export const Coords = () => {
  const { t, locale } = useLocale();
  const { disabled } = useVolumeStore();
  const { coordinates, cursor, selected, notes, removeCoordinate, selectCoordinate, setNote } = useViewerMeasurement();

  return (
    <div class="panel__table-container">
      <table class="panel__table">
        <tr class="text-100">
          <th class="text-center col-sm">{t("common.point")}</th>
          <th class="col-md">{getCoordinateName("X", locale)}</th>
          <th class="col-md">{getCoordinateName("Y", locale)}</th>
          <th class="col-md">{getCoordinateName("Z", locale)}</th>
          <th class="col-sm" />
        </tr>

        {coordinates.map((coord, i) => {
          const isCursor = i === cursor;
          const canRemove = !disabled && !isCursor && selected === undefined;
          const key = `point-${coord.x}-${coord.y}-${coord.z}${isCursor ? "-c" : ""}`;

          const remove = () => canRemove && removeCoordinate(i);
          const onClickIndex = !disabled && !isCursor ? () => selectCoordinate(i) : undefined;

          return (
            <tr key={key} class={i === selected ? "selected" : undefined}>
              <td colSpan={5} class="no-padding no-border">
                <table class="panel__table--inner">
                  <tr class="text-gray-800">
                    <td
                      rowSpan={2}
                      class={`text-center col-sm ${isCursor || disabled ? "text-gray-600" : "pointer"}`}
                      onClick={onClickIndex}
                    >
                      {i + 1}
                    </td>
                    <td class="col-md">{formatValue(coord.x)}</td>
                    <td class="col-md">{formatValue(coord.z)}</td>
                    <td class="col-md">{formatValue(coord.y)}</td>
                    <td
                      rowSpan={2}
                      title={t("buttons.remove")}
                      class={`col-sm ${canRemove ? "panel__table-remove" : "panel__table-remove--disabled"}`}
                      onClick={remove}
                    >
                      <div class="flex-center">
                        <Icon type={canRemove ? "trash" : "trashDisabled"} />
                      </div>
                    </td>
                  </tr>
                  <tr class="text-gray-800">
                    <td class="col-md">{t("panel.coords.note.label")}</td>
                    <td colSpan={2} class="small-vertical-padding col-lg">
                      <Input
                        index={i}
                        defaultValue={notes[i] || ""}
                        disabled={isCursor || disabled}
                        onChange={setNote}
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
