import { Line, LineSegments, Mesh, Points } from "three";

export function dispose(mesh: Mesh | Line | LineSegments | Points): void {
  mesh.geometry.dispose();
  Array.isArray(mesh.material) ? mesh.material.forEach((m) => m.dispose()) : mesh.material.dispose();
}

export function disposeLineMaterial(line: Line): void {
  Array.isArray(line.material) ? line.material.forEach((l) => l.dispose()) : line.material.dispose();
}
