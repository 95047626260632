import { h } from "preact";
import { Button } from "components/Toolbar/Button";
import { useViewerControls } from "hooks";
import { useLocale } from "locale";
import "./styles.css";

export const Camera = () => {
  const { t } = useLocale();
  const { zoomIn, zoomOut, perspectiveOn, toggleCamera, topView, resetCamera, verticalPanningOn, togglePanning } =
    useViewerControls();

  return (
    <div class="camera">
      <Button
        icon={"perspective"}
        title={perspectiveOn ? t("buttons.perspectiveOff") : t("buttons.perspectiveOn")}
        active={perspectiveOn}
        onClick={toggleCamera}
      />
      <Button
        icon={"vertical"}
        title={verticalPanningOn ? t("buttons.verticalOff") : t("buttons.verticalOn")}
        active={verticalPanningOn}
        onClick={togglePanning}
      />
      <Button icon={"top"} title={t("buttons.topView")} onClick={topView} />
      <Button icon={"center"} title={t("buttons.resetCamera")} onClick={resetCamera} />
      <Button icon={"zoomIn"} title={t("buttons.zoomIn")} onClick={zoomIn} />
      <Button icon={"zoomOut"} title={t("buttons.zoomOut")} onClick={zoomOut} />
    </div>
  );
};
