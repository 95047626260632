import { h } from "preact";
import { useMemo } from "preact/hooks";
import { Button } from "components/Button";
import { Loading } from "components/Panel/Footer/Loading";
import { useVolumeStore } from "store";
import { useLocale } from "locale";
import "./styles.css";

export type ControlsType = "submit" | "loading" | undefined;

export const Controls = () => {
  const { t } = useLocale();
  const { settingsChanged, loading, applyChanges } = useVolumeStore();

  const type = useMemo<ControlsType>(() => {
    if (settingsChanged) return "submit";
    if (loading) return "loading";
    return undefined;
  }, [settingsChanged, loading]);

  const height = useMemo(() => {
    if (type === "submit") return 70;
    if (type === "loading") return 70;
    return 0;
  }, [type]);

  const submitStyles = type === "submit" ? { opacity: 1, zIndex: 1 } : { opacity: 0, zIndex: -1 };
  const loadingStyles = type === "loading" ? { opacity: 1, zIndex: 1 } : { opacity: 0, zIndex: -1 };

  return (
    <div class="panel-footer__controls" style={{ height }}>
      {/** Volume Measurement Settings Submit */}
      <div class="panel-footer__controls-content" style={submitStyles}>
        <div class="panel-footer__controls-submit">
          <Button icon={"checkButton"} size={"lg"} onClick={applyChanges}>
            {t("panel.footer.applyChanges")}
          </Button>
        </div>
      </div>

      {/** Volume Measurement Loading */}
      <div class="panel-footer__controls-content" style={loadingStyles}>
        <Loading />
      </div>
    </div>
  );
};
