import { ApiData } from "viewer/core";

const LOCALHOST = "http://localhost:8000/models/";

const getUrl = (filename: string): string => LOCALHOST + filename;

// noinspection SpellCheckingInspection
export const mockData: { [key: string]: ApiData } = {
  georef: {
    model_url: getUrl("georeferencovany.glb"),
    texture_url: null,
    coordinates_url: getUrl("georeferencovany.xyz"),
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  test_model: {
    model_url:
      "https://hsrostorage.blob.core.windows.net/file3d/New_documentation_10172022_1234_PM.glb?sp=r&st=2023-05-04T07:08:52Z&se=2023-06-04T15:08:52Z&spr=https&sv=2022-11-02&sr=b&sig=0ARpipUQi%2B4Au3h%2BucHmAWDoeerNT0wpi6bM1AloRV8%3D",
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "scale",
  },
  church: {
    model_url: getUrl("church.glb"),
    texture_url: null,
    coordinates_url: getUrl("church.xyz"),
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  optim: {
    model_url: getUrl("georeferencovany_draco.glb"),
    texture_url: null,
    coordinates_url: getUrl("georeferencovany.xyz"),
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  eon_250m: {
    model_url: getUrl("EON_250m.glb"),
    texture_url: null,
    coordinates_url: getUrl("EON_250m.xyz"),
    coordinate_system: null,
    type: "georef_qr",
  },
  eon_23: {
    model_url: getUrl("EON_23.fbx"),
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "simple",
  },
  elmu_3: {
    model_url: getUrl("ELMU_3.obj"),
    texture_url: getUrl("ELMU_3_compressed.jpg"),
    coordinates_url: null,
    coordinate_system: null,
    type: "simple",
  },
  assecco: {
    model_url: getUrl("ASSECCO.obj"),
    texture_url: getUrl("ASSECCO_compressed_2.jpg"),
    coordinates_url: getUrl("ASSECCO.xyz"),
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  scale: {
    model_url: getUrl("meritko.glb"),
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "scale",
  },
  test: {
    model_url: getUrl("test_glb.glb"),
    texture_url: null,
    coordinates_url: getUrl("test_glb.xyz"),
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  test2: {
    // 0,00435 m3
    model_url: getUrl("test_exportu_obj.obj"),
    texture_url: getUrl("test_exportu_obj.jpg"),
    coordinates_url: null,
    coordinate_system: null,
    type: "scale",
  },
  test3: {
    // 4,1457 m3
    model_url: getUrl("HSRO_vykop.fbx"),
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "scale",
  },
  vertical: {
    model_url: getUrl("statue.glb"),
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "scale",
  },
  demo: {
    model_url: "https://mawisphoto-backend-staging.s3.eu-central-1.amazonaws.com/models/georeferencovany.glb",
    texture_url: null,
    coordinates_url: "https://mawisphoto-backend-staging.s3.eu-central-1.amazonaws.com/models/georeferencovany.xyz",
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  demo2: {
    model_url: "https://mawisphoto-backend-staging.s3.eu-central-1.amazonaws.com/models/HSRO_vykop.fbx",
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "scale",
  },
  demo3: {
    model_url: "https://mawisphoto-backend-staging.s3.eu-central-1.amazonaws.com/models/ASSECCO.obj",
    texture_url: "https://mawisphoto-backend-staging.s3.eu-central-1.amazonaws.com/models/ASSECCO_compressed.jpg",
    coordinates_url: "https://mawisphoto-backend-staging.s3.eu-central-1.amazonaws.com/models/ASSECCO.xyz",
    coordinate_system: "s_jtsk",
    type: "georef_qr",
  },
  error: { model_url: null, texture_url: null, coordinates_url: null, coordinate_system: null, type: null },
  invalid: {
    model_url: getUrl("invalid.glb"),
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "simple",
  },
  invalid2: {
    model_url: getUrl("ASSECCO.jpg"),
    texture_url: null,
    coordinates_url: null,
    coordinate_system: null,
    type: "simple",
  },
};
