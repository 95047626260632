import { h } from "preact";

const icons = {
  angleDown: require("assets/icons/angle-down.svg"),
  angleDownDisabled: require("assets/icons/angle-down-disabled.svg"),
  angleSelect: require("assets/icons/angle-select.svg"),
  angleUp: require("assets/icons/angle-up.svg"),
  angleUpDisabled: require("assets/icons/angle-up-disabled.svg"),
  apiError: require("assets/icons/api-error.svg"),
  area: require("assets/icons/area.svg"),
  arrowDown: require("assets/icons/arrow-down.svg"),
  arrowDownDisabled: require("assets/icons/arrow-down-disabled.svg"),
  arrowUp: require("assets/icons/arrow-up.svg"),
  arrowUpDisabled: require("assets/icons/arrow-up-disabled.svg"),
  axis: require("assets/icons/axis.svg"),
  camera: require("assets/icons/camera.svg"),
  center: require("assets/icons/center.svg"),
  check: require("assets/icons/check.svg"),
  checkButton: require("assets/icons/check-button.svg"),
  close: require("assets/icons/close.svg"),
  command: require("assets/icons/command.svg"),
  ctrl: require("assets/icons/ctrl.svg"),
  distance: require("assets/icons/distance.svg"),
  download: require("assets/icons/download.svg"),
  esc: require("assets/icons/esc.svg"),
  exclamationMarkCircleRed: require("assets/icons/exclamation-mark-circle-red.svg"),
  export: require("assets/icons/export.svg"),
  infoCircle: require("assets/icons/info-circle.svg"),
  logo: require("assets/icons/logo.svg"),
  modelError: require("assets/icons/model-error.svg"),
  mouseLeft: require("assets/icons/mouse-left.svg"),
  mouseMiddle: require("assets/icons/mouse-middle.svg"),
  mouseRight: require("assets/icons/mouse-right.svg"),
  perspective: require("assets/icons/perspective.svg"),
  polygonHorizontal: require("assets/icons/polygon-horizontal.svg"),
  polygonSmart: require("assets/icons/polygon-smart.svg"),
  questionMark: require("assets/icons/question-mark.svg"),
  questionMarkCircle: require("assets/icons/question-mark-circle.svg"),
  ruler: require("assets/icons/ruler.svg"),
  rulerCrossedOut: require("assets/icons/ruler-crossed-out.svg"),
  modelGMaps: require("assets/icons/model-g-maps.svg"),
  modelGQr: require("assets/icons/model-g-qr.svg"),
  modelGSpray: require("assets/icons/model-g-spray.svg"),
  modelSimple: require("assets/icons/model-simple.svg"),
  modelScale: require("assets/icons/model-scale.svg"),
  top: require("assets/icons/top.svg"),
  trash: require("assets/icons/trash.svg"),
  trashDisabled: require("assets/icons/trash-disabled.svg"),
  vertical: require("assets/icons/vertical.svg"),
  volume: require("assets/icons/volume.svg"),
  zoomIn: require("assets/icons/zoom-in.svg"),
  zoomOut: require("assets/icons/zoom-out.svg"),
};

export type IconType = keyof typeof icons;

interface Props {
  type: IconType;
}

export const Icon = ({ type }: Props) => <img src={icons[type]} alt={type} />;
