import { BufferGeometry, Float32BufferAttribute, Vector3 } from "three";

export function geometryToVectors(geometry: BufferGeometry): Vector3[] {
  const position = geometry.getAttribute("position").array;
  const vectors: Vector3[] = [];

  for (let i = 0; i < position.length; i = i + 3) {
    vectors.push(new Vector3(position[i], position[i + 1], position[i + 2]));
  }

  return vectors;
}

export function vectorsToPosition(vectors: Vector3[]): Float32BufferAttribute {
  return new Float32BufferAttribute(
    vectors.reduce<number[]>((p, c) => [...p, ...c.toArray()], []),
    3
  );
}
