import Stats from "three/examples/jsm/libs/stats.module";
import { AxesHelper } from "three";
import { scene } from "viewer/core/scene";

class Devtools {
  private statsFps?: Stats;
  private statsMs?: Stats;
  private statsMb?: Stats;

  constructor(enabled = false) {
    if (enabled) this.init();
  }

  public statsBegin(): void {
    this.statsFps?.begin();
    this.statsMs?.begin();
    this.statsMb?.begin();
  }

  public statsEnd(): void {
    this.statsFps?.end();
    this.statsMs?.end();
    this.statsMb?.end();
  }

  private init(): void {
    this.initStats();
    Devtools.initAxesHelper();
  }

  private initStats(): void {
    this.statsFps = Stats();
    this.statsMs = Stats();
    this.statsMb = Stats();

    this.statsFps.showPanel(0);
    this.statsMs.showPanel(1);
    this.statsMb.showPanel(2);

    this.statsFps.dom.style.position = "relative";
    this.statsMs.dom.style.position = "relative";
    this.statsMb.dom.style.position = "relative";

    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.display = "flex";
    container.style.right = "10px";
    container.style.bottom = "10px";

    container.appendChild(this.statsFps.dom);
    container.appendChild(this.statsMs.dom);
    container.appendChild(this.statsMb.dom);

    document.body.appendChild(container);
  }

  private static initAxesHelper(): void {
    const axes = new AxesHelper(1);
    scene.add(axes);

    const legend = document.createElement("div");
    legend.style.position = "absolute";
    legend.style.right = "10px";
    legend.style.bottom = "60px";

    const label = document.createElement("span");
    label.innerText = "Axes: ";
    label.style.fontSize = "12px";

    const info = document.createElement("span");
    info.innerText = "(length: 1m, position: [0, 0, 0])";
    info.style.fontSize = "12px";

    const legendX = document.createElement("span");
    legendX.innerText = "X ";
    legendX.style.color = "#ff0000";

    const legendY = document.createElement("span");
    legendY.innerText = "Y ";
    legendY.style.color = "#00ff00";

    const legendZ = document.createElement("span");
    legendZ.innerText = "Z ";
    legendZ.style.color = "#0000ff";

    legend.appendChild(label);
    legend.appendChild(legendX);
    legend.appendChild(legendY);
    legend.appendChild(legendZ);
    legend.appendChild(info);

    document.body.appendChild(legend);
  }
}

const enabled = window.location.search.includes("dev=true");

const devtools = new Devtools(enabled);

export { devtools };
