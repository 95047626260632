import { h } from "preact";
import {
  IconAxis,
  IconRuler,
  IconDistance,
  IconArea,
  IconVolume,
  IconQuestionMark,
  IconCamera,
  IconPerspective,
  IconVertical,
  IconTop,
  IconCenter,
  IconZoomIn,
  IconZoomOut,
  IconRulerCrossedOut,
} from "./IconGallery";

const icons = {
  ruler: IconRuler,
  axis: IconAxis,
  distance: IconDistance,
  area: IconArea,
  volume: IconVolume,
  questionMark: IconQuestionMark,
  camera: IconCamera,
  perspective: IconPerspective,
  vertical: IconVertical,
  top: IconTop,
  center: IconCenter,
  zoomIn: IconZoomIn,
  zoomOut: IconZoomOut,
  rulerCrossedOut: IconRulerCrossedOut,
};

export type IconComponentsType = keyof typeof icons;

interface Props {
  type: IconComponentsType;
  color?: string;
  width?: number;
  height?: number;
}

export const IconComponent = ({ type, color, width, height }: Props) => {
  const Icon = icons[type];
  return <Icon color={color} width={width} height={height} />;
};
