import { h } from "preact";
import { useCallback, useEffect } from "preact/hooks";
import { Modal, useModal } from "components/Modal";
import { Icon, IconType } from "components/Icon";
import { useLocale } from "locale";
import { useUIStore } from "store";
import "./styles.css";

export const Help = () => {
  const { t } = useLocale();
  const { help, setHelp } = useUIStore();

  const { isOpen, open, close } = useModal(() => setHelp(false));

  const handleClose = useCallback(() => {
    close();
    setHelp(false);
  }, [close, setHelp]);

  useEffect(() => {
    if (help && !isOpen) open();
  }, [help, isOpen, open]);

  return (
    <Modal isOpen={isOpen} header={{ value: t("help.title"), closeButton: true }} onClose={handleClose}>
      <Section
        heading={t("help.mouse.title")}
        rows={[
          { icon: "mouseLeft", text: t("help.mouse.left") },
          { icon: "mouseMiddle", text: t("help.mouse.middle") },
          { icon: "mouseRight", text: t("help.mouse.right") },
        ]}
      />

      <Section heading={t("help.keyboard.title")} rows={[{ text: t("help.keyboard.arrows") }]} />
    </Modal>
  );
};

interface SectionProps {
  heading: string;
  rows: { icon?: IconType; text: string }[];
}

const Section = ({ heading, rows }: SectionProps) => (
  <div class="help__content-section">
    <span class="block text-300 text-medium text-gray-800">{heading}</span>

    {rows.map(({ icon, text }, i) => (
      <div key={i} class="help__content-row">
        {icon && <Icon type={icon} />}
        <span class="text-gray-800">{text}</span>
      </div>
    ))}
  </div>
);
