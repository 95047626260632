import { h, JSX } from "preact";
import { useCallback } from "preact/hooks";
import { useLocale } from "locale";
import "./styles.css";

interface Props {
  index: number;
  defaultValue?: string;
  disabled?: boolean;
  onChange: (index: number, value: string) => any;
}

export const Input = ({ index, defaultValue = "", disabled = false, onChange }: Props) => {
  const { t } = useLocale();

  const handleKeyUp = useCallback<JSX.GenericEventHandler<HTMLInputElement>>(
    (e) => onChange(index, e.currentTarget.value),
    [index, onChange]
  );

  return (
    <input
      name={`note-${index}`}
      type={"text"}
      value={defaultValue}
      placeholder={t("panel.coords.note.placeholder")}
      maxLength={15}
      disabled={disabled}
      class="panel__input"
      onKeyUp={handleKeyUp}
    />
  );
};
