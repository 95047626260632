import { Languages } from "locale";

type Coordinate = "X" | "Y" | "Z";

const commonNames: Record<Coordinate, string> = { X: "X", Y: "Y", Z: "Z" };
const deNames: Record<Coordinate, string> = { X: "X (Hochwert)", Y: "Y (Rechtswert)", Z: "Z (Höhe)" };

export function getCoordinateName(coordinate: Coordinate, locale: Languages): string {
  return locale === Languages.de ? deNames[coordinate] : commonNames[coordinate];
}
