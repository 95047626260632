import { h } from "preact";
import { Icon, IconType } from "components/Icon";
import "./styles.css";

type Size = "md" | "lg";

interface Props {
  children: string;
  icon?: IconType;
  size?: Size;
  disabled?: boolean;
  onClick?: () => any;
}

export const Button = ({ children, icon, size = "md", disabled = false, onClick }: Props) => {
  return (
    <button class={`button ${size === "lg" ? "button--lg" : ""}`} disabled={disabled} onClick={onClick}>
      {icon && <Icon type={icon} />}
      {children}
    </button>
  );
};
