import { Vector3 } from "three";
import { ConversionUnit, convertValue, Unit } from "utils/format";
import { Height, Mode, RealProjected, VolumeValues } from "viewer/measurement/interface";

/** msSaveOrOpenBlob was removed from TS definitions, but it's still valid for IE */
declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Navigator {
    msSaveOrOpenBlob?: (blob: Blob, fileName: string) => void;
  }
}

export function downloadModelScreenshot(data: string): void {
  const fileName = "model_snapshot.jpg";

  if (blobDownloadRequired()) return downloadModelScreenshotIE(data, fileName);

  const a = document.createElement("a");

  a.href = data;
  a.download = fileName;
  a.click();
}

const defaultCoordsCsvHeader = { point: "#", x: "X", y: "Y", z: "Z", coordinateSystem: "System", code: "Code" };
export type CoordsCsvHeaderValues = typeof defaultCoordsCsvHeader;

export function downloadCoordsCsv(
  coordinates: Vector3[],
  notes: string[],
  coordinateSystem?: string,
  headerValues: CoordsCsvHeaderValues = defaultCoordsCsvHeader
): void {
  const hasNotes = notes.filter((value) => value.trim().length > 0).length > 0;

  const header = [headerValues.point, headerValues.x, headerValues.y, headerValues.z];
  if (coordinateSystem) header.push(headerValues.coordinateSystem);
  if (hasNotes) header.push(headerValues.code);

  const rows = coordinates.map((coord, i) => {
    const row = [i + 1, coord.x.toFixed(2), coord.z.toFixed(2), coord.y.toFixed(2)];

    if (coordinateSystem) row.push(coordinateSystem);
    if (hasNotes) row.push(notes[i] ?? "");

    return row;
  });

  const fileName = "model_coords.csv";
  const data = header.join(";") + "\n" + rows.map((row) => row.join(";")).join("\n");

  if (blobDownloadRequired()) return downloadCsvIE(data, fileName);

  const csvContent = "data:text/csv;charset=utf-8," + data;
  const a = document.createElement("a");

  a.href = encodeURI(csvContent);
  a.download = fileName;
  a.click();
}

const defaultValuesCsvLabels = {
  lengthReal: "",
  lengthProjected: "",
  volumeFill: "",
  volumeCut: "",
  volumeNetFill: "",
  volumeIncompleteness: "",
  precision: "",
  areaReal: "",
  areaProjected: "",
  perimeterReal: "",
  perimeterProjected: "",
  heightMin: "",
  heightMax: "",
  heightDiff: "",
};
export type ValuesCsvLabelValues = typeof defaultValuesCsvLabels;

const defaultValuesCsvHeader = {
  measurement: "",
  values: "",
};
export type ValuesCsvHeaderValues = typeof defaultValuesCsvHeader;

export function downloadValuesCsv(
  mode: Mode,
  inScale: boolean,
  conversion: ConversionUnit,
  length: RealProjected,
  volume: VolumeValues,
  area: RealProjected,
  perimeter: RealProjected,
  height: Height,
  labelValues: ValuesCsvLabelValues = defaultValuesCsvLabels,
  headerValues: ValuesCsvHeaderValues = defaultValuesCsvHeader
): void {
  const label = (key: keyof ValuesCsvLabelValues, unit: string) => {
    return labelValues[key] + (inScale ? ` [${unit.replace("m", conversion)}]` : "");
  };

  const value = (value?: number, unit: Unit = "m", precision = 2): string => {
    return convertValue(value, unit, conversion)?.toFixed(precision) || "";
  };

  const rows: [string, string][] = [[headerValues.measurement, headerValues.values]];

  if (mode === "distance") {
    rows.push(
      [label("lengthReal", "m"), value(length.real, "m")],
      [label("lengthProjected", "m"), value(length.projected, "m")]
    );
  }

  if (mode === "volume") {
    rows.push(
      [label("volumeFill", "m³"), value(volume.fill, "m³", 3)],
      [label("volumeCut", "m³"), value(volume.cut, "m³", 3)],
      [label("volumeNetFill", "m³"), value(volume.netFill, "m³", 3)],
      [label("volumeIncompleteness", "%"), value(volume.incompleteness, "%")],
      [`${labelValues.precision} [mm]`, volume.precision ? Math.round((volume.precision || 0) * 1000).toString() : ""]
    );
  }

  if (mode === "area" || mode === "volume") {
    rows.push(
      [label("areaReal", "m²"), value(area.real, "m²")],
      [label("areaProjected", "m²"), value(area.projected, "m²")],
      [label("perimeterReal", "m"), value(perimeter.real, "m")],
      [label("perimeterProjected", "m"), value(perimeter.projected, "m")]
    );
  }

  rows.push(
    [label("heightMin", "m"), value(height.min, "m")],
    [label("heightMax", "m"), value(height.max, "m")],
    [label("heightDiff", "m"), value(height.diff, "m")]
  );

  const fileName = "model_values.csv";
  const data = rows.map((row) => row.join(";")).join("\n");

  if (blobDownloadRequired()) return downloadCsvIE(data, fileName);

  const csvContent = "data:text/csv;charset=utf-8," + data;
  const a = document.createElement("a");

  a.href = encodeURI(csvContent);
  a.download = fileName;
  a.click();
}

function downloadModelScreenshotIE(data: string, fileName: string): void {
  const content = data.replace("data:image/jpeg;base64,", "");
  const byteCharacters = window.atob(content);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.charCodeAt(i);

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "image/jpeg" });

  window.navigator.msSaveOrOpenBlob?.(blob, fileName);
}

function downloadCsvIE(data: string, fileName: string): void {
  const byteNumbers = new Array(data.length);

  for (let i = 0; i < data.length; i++) byteNumbers[i] = data.charCodeAt(i);

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "text/csv" });

  window.navigator.msSaveOrOpenBlob?.(blob, fileName);
}

function blobDownloadRequired(): boolean {
  return Boolean(window.navigator && window.navigator.msSaveOrOpenBlob);
}
